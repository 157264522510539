import React from 'react';
import { withRouter } from 'react-router-dom';
import Page from '../Page';

class BestPractices extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  componentDidMount() {
    if (this.props.location.pathname.includes('best-practices')) {
      this.setState({ isVisible: true }, () => {
        this.props.setBestPrcaticeState(true)
      })
    }
  }

  render() {

    const pageData = this.props.config.data.filter((item) => item.path.includes('best-practices') && item.path === this.props.location.pathname )

    const path = this.props.match.params.lang + "/best-practices"

    return (
      <React.Fragment>
        <div className="dd-subsite-content">
          {<Page path={path} data={pageData[0]} menu={null} {...this.props} /> }
        </div>
      </React.Fragment>
      )
  }
}

export default withRouter(BestPractices)