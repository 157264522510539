import React, { Component } from 'react';


import { translate } from '../../helpers';

import '../../../node_modules/font-awesome/css/font-awesome.min.css';

class CartWidget extends Component {

  deleteItem = (item, index) => {
    this.props.deleteFromCart(item, index)
  }

  render() {
    const data = this.props.data || []
    const iconStyle = {
      height: '14px'
    }

    // const addSvg = require('../../assets/svg-icons/add.svg');
    const downloadSvg = require('../../assets/svg-icons/download.svg');
    const trashSvg = require('../../assets/svg-icons/close.svg');

    return (
      <table className="table dd-table js-dd-table mb-8">
        <thead>
          <tr>
            <th scope="col" className="col-50">{translate("global","name")}</th>
            <th scope="col">{translate("global","typ")}</th>
            <th scope="col">{translate("global","size")}</th>
            <th scope="col" className="text-right">Download</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => {
            return <tr key={index}>
              <td>{item.title}</td>
              <td>{item.fileType}</td>
              <td>{item.fileSize}</td>
              <td>
                <div className="font-icon-detail text-right" >
                  <span title="Download" data-toggle="tooltip" data-placement="bottom">
                    <a href={item.file} target="_blank" rel="noopener noreferrer">
                      <img src={downloadSvg} style={iconStyle} alt='' data-toggle="tooltip" data-placement="bottom" title="Download"/>
                    </a>
                  </span>
                  <span  onClick={()=>this.deleteItem(item, index)} data-toggle="tooltip" data-placement="bottom" title={translate('cart', 'delete_from_cart')}>
                    <img src={trashSvg} style={iconStyle} alt='' />
                  </span>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    )
  }
}
export default CartWidget