import React from 'react';

const ColorCircle = (props) => {

  const styles = (props.hex === '#ffffff') ? {
    height: props.diameter,
    width: props.diameter,
    backgroundColor: props.hex,
    borderRadius: '50%',
    display: 'inline-block',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#a2aaad',
    marginRight: '10px',

  } : {
      height: props.diameter,
      width: props.diameter,
      backgroundColor: props.hex,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '10px',
    }

  return (
    <div style={styles}></div>
  )
}
export default ColorCircle
