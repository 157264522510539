import React from 'react';
import { withRouter } from 'react-router-dom';


import DownloadsWidget from '../../Modules/DownloadsWidget'

// import {media} from '../../Data/staticData';
import {imageDetail} from '../../../Data/staticData';

class ImageDetail extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      mediaItem: null
    };
  }

  getMediaItemById(id) {
    return imageDetail;
    // return media.find( item => item.id === parseInt(id, 10) );
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    const mediaItem = this.getMediaItemById(params.imageID)

    if (typeof(mediaItem) !== 'undefined') {
      this.setState({ mediaItem });
    }
  }

  handleCloseClick = () => {
    this.props.history.goBack()
  }

  render() {

    const closeSvg = require('../../../assets/svg-icons/close.svg');

    if (!this.state.mediaItem) {
      return <p>Loading ...</p>;
    }

    const downloads = this.state.mediaItem.downloads

    return (
      <div className="container">
        <section className="content-section">

        <div className="container">
          <div className="row">
            <div className="col-md-auto text-left content-section-heading">
              <h2 className="mb-5">{this.state.mediaItem.title}</h2>
            </div>
            <div className="col-sm text-right">
              <span onClick={this.handleCloseClick} style={{cursor:'pointer'}} >
                <img src={closeSvg} alt="back" style={{height:'50%'}} />
              </span>
            </div>
          </div>
        </div>

          <div className="row mt-4">
            <div className="col-md-6 ">
              <img src={this.state.mediaItem.src} className="img-fluid" alt="" />

              <div className="mt-4">
                <h3>Beschreibung</h3>
                {this.state.mediaItem.description}
              </div>

              <div className="mt-4">
                <h3>Keywords</h3>
                {this.state.mediaItem.keywords.map((item, index) => {
                    return <React.Fragment key={index}>{item}, </React.Fragment>
                  })
                }
              </div>
            </div>

            <div className="col-md-6">
              <div className="">
                <h3>Downloads</h3>
                <DownloadsWidget  {...this.props}  hasHeader={false}  data={downloads}/>
              </div>

              <div className="mt-5">
                <h3>Metadaten</h3>
                  <table className="table table-sm table-striped">
                  <tbody>
                    <tr>
                      <td>Size</td>
                      <td>{this.state.mediaItem.metadata.size}</td>
                    </tr>
                    <tr>
                      <td>Format</td>
                      <td>{this.state.mediaItem.metadata.format}</td>
                    </tr>
                    <tr>
                      <td>Filesize</td>
                      <td>{this.state.mediaItem.metadata.filesize}</td>
                    </tr>
                    <tr>
                      <td>Uploaded by</td>
                      <td>{this.state.mediaItem.metadata.uploadUser}</td>
                    </tr>
                    <tr>
                      <td>Modified on</td>
                      <td>{this.state.mediaItem.metadata.modified}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              </div>
            <div className="col-md-12">
              <div className="mt-5">
                <h3>Rechtliches</h3>
                <p>{this.state.mediaItem.publication}</p>
              </div>
              </div>
            </div>
          </section>
      </div>
    )
  }
}

export default withRouter(ImageDetail)