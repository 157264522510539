import React, { Component } from 'react';

import Carousel from 'react-bootstrap/Carousel'

class Slider extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  render() {
    const { index, direction } = this.state;

    let anchor = `carousel-${Date.now()}`
    if (typeof (this.props.anchor) !== 'undefined') {
      anchor = this.props.anchor
    }
    let width = '100%'
    if (typeof (this.props.width) !== 'undefined') {
      width = this.props.width
    }

    let images = {
      portrait: [
        "/img/bild.jpg",
        "/img/bild3.jpg",
        "/img/bild4.jpg",
        "/img/bild5.jpg",
        "/img/bild6.jpg",
      ], landscape: [
        "https://source.unsplash.com/0MGhdhObDXA/600x800",
        "https://source.unsplash.com/ImqbTNUoTD0/600x800",
        "https://source.unsplash.com/k5gGr6jMqQk/600x800",
        "https://source.unsplash.com/m73AoqqwE5I/600x800",
      ]
    }

    if (this.props.anchor === "ppt-slider") {
      images = {
        portrait: [
          "/img/logo/png/ppt_16_9.png",
          "/img/logo/png/ppt_4_3.png"
        ],
        landscape: [
          "https://source.unsplash.com/0MGhdhObDXA/600x800",
          "https://source.unsplash.com/ImqbTNUoTD0/600x800",
        ]
      }
    }


    if (this.props.anchor === "web-and-print-slider") {
      images = {
        portrait: [
          "/img/logo/png/roechling_29.png",
          "/img/logo/png/roechling_29.png",
        ],
        landscape: [
          "https://source.unsplash.com/0MGhdhObDXA/600x800",
          "https://source.unsplash.com/ImqbTNUoTD0/600x800",
        ]
      }
    }

    let imagesKey = 'portrait'
    if (typeof (this.props.items) !== 'undefined') {
      imagesKey = this.props.items
    }


    return (
      <div id={anchor} style={{ width }}>
        <Carousel
          activeIndex={index}
          direction={direction}
          onSelect={this.handleSelect}
        >
          {
            images[imagesKey].map((item, index) => {
              return <Carousel.Item key={index} >
                <img className="d-block w-100" src={item} alt="First slide" />
                {/* <Carousel.Caption>
                  <h3>Slide label</h3>
                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
              </Carousel.Item>
            })
          }
        </Carousel>
      </div>
    );
  }
}

export default Slider;