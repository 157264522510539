import React from 'react';
import { withRouter } from 'react-router-dom';

import { getLoginUrl, getLanguage } from '../../../config'
import { translate, loadAPIData } from '../../../helpers'

import { store } from '../../../Data/Store'
import { goHome } from '../../../Data/actions/actions'

import Alert from '../../../Components/Modules/Alert'

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios");


class Login extends React.Component {

  constructor() {
    super()
    this.state = {
      mail: '',
      password: '',
      hasError: false,
      errorMessage: ''
    }
  }

  handleHide = () => this.setState({ hasError: false });

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const state = this.state
    state[name] = value
    this.setState({ ...state });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.mail && this.state.password) {

      const data = new FormData();
      data.append("username", this.state.mail)
      data.append("password", this.state.password)

      this.props.setLoadingState(true)

      axios.post(getLoginUrl(), data, { withCredentials: true } )
      .then(async response => {
        try {
          const data = await loadAPIData()
          await this.props.saveData(data)
          this.props.loginUser()
          this.props.setLoadingState(false)
          store.dispatch(goHome())
        }
        catch (err) {
          console.log(err)
          this.props.setLoadingState(false)
          const errorMessage = (err.response) ? err.response : 'Error'
          this.setState({ hasError: true, text: errorMessage })
        }
      })
      .catch(err => {
        console.log(err)
        this.props.setLoadingState(false)
        const errorMessage = (err.response && (err.response.status === 403)) ? 'E-Mail-Adresse und/oder Passwort ist falsch.' : 'Bitte wenden Sie sich an Ihren Admin'
        this.setState({ hasError: true, errorMessage })
      })
    }
  }

  componentDidMount() {
    this.props.setBestPrcaticeState(false)
  }

  render() {

    const lang = getLanguage()

    return (
      <React.Fragment>
        <section className="content-section" id="login-page">
          {
            this.state.hasError &&
            <div className="container mb-6 px-0">
              <Alert show={this.state.hasError} text={this.state.errorMessage} handleHide={this.handleHide}/>
            </div>
          }
          <form className="dd-from" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="dd-auth-form">
            <div className="container bg-primary text-white">
              <div className="row py-5">
                <div className="col-lg-10 offset-lg-1">
                  <h3 className="display-3 text-white">{translate("login","sign_in")}</h3>
                    <div className="form-group">
                      <input type="email" className="form-control" name="mail" aria-describedby="emailHelpId" placeholder={translate("auth","e-mail")} onChange={this.handleChange}/>
                    </div>

                    <div className="form-group mb-5">
                      <input type="password" className="form-control" name="password" id="password" placeholder={translate("auth","password")} onChange={this.handleChange}/>
                    </div>

                    {/*
                    <div className="form-check form-check-inline mb-4">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="" id="" value="checkedValue" />{translate("login","remember_me")}
                        </label>
                    </div>
                    */}
                    <div className="container mb-5">
                      <div className="form-group row justify-content-between dd-auth-form__buttons">
                        <button type="submit" className="btn btn-secondary">{translate("login","login")}</button>

                        <a href={`/${lang}/auth/register`}>{translate("login", "new_account")}</a>
                      </div>
                    </div>
                    <a href={`/${lang}/auth/forgot-password`}>{translate("auth","forgot_password")}</a>
                </div>
              </div>
            </div>
          </div>
          </form>
        </section>
      </React.Fragment>
    )
  }
}
export default withRouter(Login)


