import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import FilterListItem from './FilterListItem'

const styles = {
  grey: {
    color: '#868F97',
  },
  spacing: {
    paddingBottom: '80px',
  },
  filterTerm: {
    display: 'inline',
    paddingLeft: '20px',
    color: '#868F97',
  },
  filterTermClose: {
    height: '10px',
    paddingLeft: '3px',
    position: 'relative',
    top: '-1px',
    strokeWidth: '2em',
  },
  pSpacing: {
    paddingBottom: '20px',
  },
  button: {
    border: 'none',
    outline: 'none',
  },
  buttonColor: {
    border: 'none',
    outline: 'none',
    color: '#0064b2',
  },
}


class FilterOptions extends Component {

  render() {

  const closeSvg = require('../../../assets/svg-icons/close.svg');
  const filterOptions = this.props.filterOptions

    return (
      <React.Fragment>
      <div className="row" style={styles.spacing}> {
        filterOptions.map(item => {
          return <Col key={item[0].label} >
            <h5>{item[0].label}</h5>
            <hr />
            { item[0].values.map(data => {
                const filterTerms = this.props.filterTerms
                const buttonStyle = (filterTerms.includes(data.label)) ? styles.buttonColor : styles.button
                return <FilterListItem key={data.label} style={buttonStyle} select={this.props.handleSelectChange} item={data} filter={item[0].filterType} />
              })
            }
          </Col>})}
          <Col xs={6} md={4}></Col>
      </div>
      {(this.props.filterTerms.length > 0) &&
        <div
          className="row"
          style={styles.pSpacing}
        >
          <Col style={styles.grey} > Filter: {
              this.props.filterTerms.map(item => {
                return <button key={item} style={styles.button} onClick={() => this.props.filterTermClick(item)} >
                    <div style={styles.filterTerm} >{item} <img src={closeSvg} style={styles.filterTermClose} alt="delete" /></div>
                  </button>
              })
            }
          </Col>
        </div>
      }
      </React.Fragment>
    )
  }
}

export default FilterOptions