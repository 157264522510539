import React from 'react';

function Headline (props) {

  const CustomTag = `h${props.heading}`

  const styles = {
    color: `${props.color}`,
    paddingBottom: `${props.paddingBottom}`,
  }

  return (
    <CustomTag className={props.className} style={styles}>{props.text}</CustomTag>
  )
}
export default Headline