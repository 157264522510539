import React from 'react';
import Loading from './../Layout/Loading'

class GlobalSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    }
  }

  render() {
    const showLoading = this.props.config.loading

    return (
      <React.Fragment>
        {this.props.children}
        <Loading showLoading={showLoading} />
      </React.Fragment>
    )
  }
}

export default GlobalSpinner;