import React, { Component } from 'react';

import {sanitizeHTML} from '../../../helpers'

class List extends Component {


  render() {

    return (
      <div className={this.props.className}>
        <ul className="dd-list">
        {
          this.props.data.map((item, index) => {
            return <li key={index} ><div dangerouslySetInnerHTML={{__html: sanitizeHTML(item)}}></div></li>
            })
        }
        </ul>
      </div>
    )
  }
}

export default List