import React from 'react'

// svg-sprite
import '../../assets/icon/sprite/svg-symbols.css'
import icons from '../../assets/icon/sprite/svg-symbols.svg'


const SvgIcon = props => {
	return (
    <span className={`icon ${props.name}`} aria-hidden="true">
      <span className="icon__inner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className={`icon icon-${props.name}`}
          >
          <use xlinkHref={`${icons}#${props.name}`} />
        </svg>
      </span>
    </span>
	)
}

export default SvgIcon
