import React from 'react';
import { withRouter } from 'react-router-dom';
import { history } from './../Data/Store';
import { getLanguage } from './../config.js';
import { translate } from './../helpers';

class BestPracticeButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    }
  }

  toggleBestPractice = () => {

    const val = this.state.isVisible
    const lang = getLanguage()
    const redirectPath = (val) ? `/${lang}` : `/${lang}/best-practices`

    this.setState({isVisible: !val }, () => {
      this.props.setBestPrcaticeState(!val)
      history.push(redirectPath);
    })
  }

  componentDidMount() {

    if (this.props.location.pathname.includes('/best-practices')) {
      this.setState({ isVisible: true })
    }
  }

  render() {
    const isVisible = this.props.config.bestPractiseVisible || false
    const logged_in = this.props.config.logged_in || false

    const subsiteButtonStyle = (isVisible) ? { left: "0", right: "unset" } : {}
    const subsiteButtonClass = (isVisible) ? 'dd-subsite-button-wrap is-active' : 'dd-subsite-button-wrap dd-no-print'
    const buttonText = (isVisible) ? translate("best_practices","back") : "Best Practice"

    return (
      <React.Fragment>
        { logged_in &&
          <div onClick={() => this.toggleBestPractice()} style={subsiteButtonStyle} className={subsiteButtonClass}>
            <div className="dd-subsite-button">{buttonText}</div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(BestPracticeButton);