import React, { Component } from 'react';

import Arrow from '../../Modules/Arrow'
import Feature from '../../Modules/Feature/Feature';

import { getLanguage } from '../../../config';
import { history } from '../../../Data/Store';


class StorySlider extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: [],
    };
  }

  loadData = async () => {
    // this.setState({ isLoading: true });

    // const esc = encodeURIComponent;
    // const query = Object.keys(params)
    //   .map(k => esc(k) + '=' + esc(params[k]))
    //   .join('&')

    try {
      // fetch(`../../../exampleSearch.json?${query}`)
      fetch(`../../../exampleStoriesSlider.json`)
        .then(response => response.json())
        .then(data => this.setState({ data }))
    }
    catch (error) {
      const lang = getLanguage()
      history.push(`/${lang}`);
    }
  }

  componentDidMount() {
    this.loadData()
  }


  render () {
    const size = '40px'

    const half = size.split('px')[0]/2
    const top = 220 - half

    const styles = {
      border: 'none',
      position: 'relative',
      top: top,
      background: 'transparent',
    }

    return (
      <React.Fragment>
        <div className="col-lg-1">
          <button style={styles} onClick={() => this.loadData()}>
            <Arrow
              key="left"
              direction="left"
              size={size}
            />
          </button>
        </div>
        {this.state.data.data &&
          this.state.data.data.map((child, index) => {
            return (<div key={index} className="col-lg-5">
              <Feature
                href={child.path}
                meta=""
                title={child.title}
                image={child.image}
              />
            </div>)
          })
        }
        <div className="col-lg-1">
          <button style={styles} onClick={() => this.loadData()}>
            <Arrow
              key="right"
              direction="right"
              size={size}
            />
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default StorySlider