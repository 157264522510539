import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown'

import TopBarSearchWidget from '../Components/Modules/Search/TopBarSearchWidget'
import SearchFeature from '../Components/Modules/Search/SearchFeature'

import { history } from '../Data/Store';
import { setLocalizationByHost, getLanguage} from '../config';
import { loadAPIData, translate } from '../helpers';

import '../../node_modules/font-awesome/css/font-awesome.min.css';

class FrontNavigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sidenavCollapsed: true,
      activeNavItem: '',
      searchTerm: '',
      searchFeature: false,
      lang: 'EN'
    };
  }

  showSearchFeature = (params) => {
    const searchTerm = params
    this.setState({ searchTerm, searchFeature: true })
  }

  hideSearchFeature = () => {
    this.setState({ searchFeature: false })
  }

  toggleSidebar = () => {
    this.setState({
      sidenavCollapsed: !this.state.sidenavCollapsed
    });
  }

  setLang = (lang) => {
    let toReplace = '/en/'
    let replacement = '/de/'

    if (lang === 'EN') {
      toReplace = '/de/'
      replacement = '/en/'
    }

    let newPath = '/de'

    if (window.location.pathname === '/') {
      if (getLanguage() === 'en') {
        newPath = '/de'
      }
    } else if (window.location.pathname === '/en') {
      newPath = '/de'
    } else if (window.location.pathname === '/de') {
      newPath = '/en/'
    } else {
      newPath = window.location.pathname.replace(toReplace, replacement)
    }

    this.props.setLoadingState(true)

    this.setState({
      lang: lang
    }, async () => {
      history.push(newPath)
      setLocalizationByHost()
      const data = await loadAPIData()
      await this.props.saveData(data)
      // FIXME
      // double history.push: (
      //  1. is needed for setLocilization
      //  2. loadAPIData is somehow resetting pathname
      // )
      history.push(newPath)
      this.props.setLoadingState(false)
    });
  }

  actionLogout = () => {
    this.props.setLoggedInState(false)
    const lang = getLanguage()
    const HOST = process.env.REACT_APP_HOST
    const logout = `${HOST}/${lang}/user/logout`
    window.location.href = logout
  }

  actionUserProfile = () => {
    const lang = getLanguage()
    const userProfilePath = (this.props.config.logged_in) ? `/${lang}/auth/profile` : `/${lang}/auth/login`
    history.push(userProfilePath)
  }

  componentDidMount() {
    this.setState({ lang: getLanguage().toUpperCase(), cart: this.props.cart })
  }

  renderBadge = () => {
    if (this.props.cart.length !== 0) {
      return <span className="badge badge-danger" style={{ marginLeft: "3px", display: "inline-block" }}>{this.props.cart.length}</span>
    }
    return ''
  }

  render() {

    const lang = getLanguage()
    const cartPath = '/' + lang +'/cart'
    const searchPath = '/' + lang +'/search'

    const logo = require('../assets/svg-icons/logo.svg');
    // const brandPortal = require('../assets/svg-icons/brand_portal.svg');
    // const bestPractice = require('../assets/svg-icons/best_practice.svg');

    // const showBestPracticeLogo = (window.location.pathname.includes('best-practices') ) ? true : false

      return (
        <React.Fragment>
          <nav id="frontnavigation" className="navbar navbar-expand navbar-light bg-white sticky-top shadow-sm px-3 px-sm-4 px-lg-5" >
            <a className="navbar-brand" href={`/${lang}/`}><img className="navbar__logo" src={logo} alt="Röchling Brand Portal" /></a>
            <TopBarSearchWidget showResults={this.showSearchFeature} />

            {
             //  (showBestPracticeLogo) ?
             //    <img  className="dd-bestpractice-logo d-none d-md-block" src={bestPractice} alt="Best Practice" style={{margin: "0 40px"}} />
             // :
             //    <img className="dd-brandportal-logo d-none d-md-block" src={brandPortal} alt="Brand Portal" style={{margin: "0 40px"}} />
            }


            <ul className="navbar-nav ml-auto ml-md-0">
              <li className="nav-item no-arrow mr-md-4 dd-search__link--mobile">
                <div className="font-icon-detail">
                  <NavLink className="nav-link" to={searchPath}>
                    <i className="fas fa-search"></i>
                  </NavLink>
                </div>
              </li>
              { this.props.config.logged_in ? (
                <li className="nav-item dropdown no-arrow mr-md-4">
                  <div className="font-icon-detail">
                    <NavDropdown title={<span><i className="far fa-user fa-fw"></i></span>} id="nav-dropdown">
                      <NavDropdown.Item onClick={() => this.actionUserProfile()}>{translate('navigation', 'profile')}</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => this.actionLogout()}>{translate('navigation', 'logout')}</NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </li>
              )
              :
                (
                  <li className="nav-item dropdown no-arrow mr-md-4">
                    <div className="font-icon-detail">
                      <NavDropdown title={<span><i className="far fa-user fa-fw"></i></span>} id="nav-dropdown">
                        <NavDropdown.Item onClick={() => this.actionUserProfile()}>Login</NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </li>
                )
            }
              { this.props.config.logged_in && (
                <li className="nav-item dropdown no-arrow mr-md-4">
                  <div>
                    <NavLink className="nav-link" to={cartPath}>
                      <i className="fa fa-cart-plus fa-fw" ata-toggle="tooltip" data-placement="bottom" title={translate('cart', 'cart')}></i>
                      {this.renderBadge()}
                    </NavLink>
                  </div>
                </li>
              )}
              <NavDropdown title={this.state.lang} id="nav-dropdown">
                <NavDropdown.Item eventKey="4.1" onClick={() => this.setLang('DE')}>DE</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2" onClick={() => this.setLang('EN')}>EN</NavDropdown.Item>
              </NavDropdown>
            </ul>
            <div id="navbar-mobile-trigger"></div>
          </nav>
          {(this.state.searchFeature) &&
            <SearchFeature
              hideSearchFeature={this.hideSearchFeature}
              searchTerm={this.state.searchTerm}
            />
          }
        </React.Fragment>
      );
    }
  }


export default FrontNavigation;