import React from 'react';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

const styles = {
  buttonItem: {
    border: 0,
    outline: 0,
  },
  button: {
    border: '1px solid #04bbed',
    outline: 0
  },
}

class SearchWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
    }
  }

  changeQuery = (event) => {
    this.setState({ searchTerm: event.target.value })
  }

  doSearch = (event) => {
    event.preventDefault();
    this.props.searchCallback(this.state.searchTerm)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ searchTerm: nextProps.searchTerm });
  }

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.doSearch}>
          <InputGroup style={styles.button}>
            <FormControl
              placeholder="Suche"
              aria-label="Search"
              onChange={this.changeQuery}
              style={styles.buttonItem}
              value={this.state.searchTerm}
            />
            <InputGroup.Append>
              <Button variant="outline-secondary" style={styles.buttonItem} onClick={this.doSearch}><i className="fas fa-search"></i></Button>
            </InputGroup.Append>
          </InputGroup>
        </form>
      </React.Fragment>
    )
  }
}

export default SearchWidget