import React, { Component } from 'react';
// import { debounce } from "throttle-debounce";

import { history } from '../../../Data/Store';

import { translate } from '../../../helpers';

import { getLanguage } from '../../../config';


class TopBarSearchWidget extends Component {

  constructor(props) {
    super(props);
    //this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.state = {
      searchTerm: '',
    };
  }

  onChange = (event) => {
    this.setState({ searchTerm: event.target.value })
  }

  showResults = (event) => {
    const term = event.target.value;
    this.autocompleteSearchDebounced(term);
  }

  autocompleteSearch = (term) => {
    this.props.showResults(term)
  }

  doSearch = (event) => {
    event.preventDefault()
    const lang = getLanguage()
    history.push(`/${lang}/search?searchTerm=${this.state.searchTerm}`)
  }


  render() {
    return (
      <form onSubmit={this.doSearch} className="search-form ml-auto mr-md-3 dd-search__link--desktop">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={translate("search_modul", "search_placeholder")}
            aria-label="Search"
            aria-describedby="basic-addon2"
            onChange={this.onChange}
          />
          <div className="input-group-append" onClick={this.doSearch}>
            <i className="fas fa-search form-control-feedback"></i>
          </div>
        </div>
      </form>
    )
  }
}

export default TopBarSearchWidget
