import React, { Component } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SearchFeatureFullText from './SearchFeatureFullText'
import SearchFeatureImages from './SearchFeatureImages'
import SearchFeatureDownloads from './SearchFeatureDownloads'

const styles = {
  colorBlue: {
    color: '#0064b2',
  },
  spacing: {
    padding: '80px',
    position: 'fixed',
    background: 'white',
    zIndex: 300
    // paddingBottom: '80px',
  },
  headline: {
    color: '#0064b2',
    paddingBottom: '30px',
  },
}


class SearchFeature extends Component {

  constructor(props) {
    super(props);
    this.escFunction = this.escFunction
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.hideSearchFeature()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {

    return (
      <React.Fragment>
        <Row style={styles.spacing}>
          <Col lg={3}>
            <SearchFeatureFullText
              search={this.props.searchTerm}
            />
          </Col>
          <Col md={false} lg={1}>
            <div className="dd-vertical-divider"> </div>
          </Col>
          <Col lg={{ span: 3 }}>
          <SearchFeatureDownloads
          search={this.props.searchTerm}
          />
          </Col>
          <Col md={false} lg={1}>
          <div className="dd-vertical-divider"> </div>
          </Col>
          <Col lg={{ span: 2 }}>
            <SearchFeatureImages
              search={this.props.searchTerm}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default SearchFeature