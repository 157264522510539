import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom'

import { getLanguage } from './../config'

const styles = {
  sidebar: {
    paddingTop: '45px',
  },
  menuLink: {
    fontWeight: '900',
    display: 'inline',
    marginLeft: '1.5rem',
    marginBottom: '2rem',
    padding: '2px',
    fontSize: '1.1em',
    fontFamily: '"EuclidSquare Regular", sans-serif'
  },
  menu: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#0064b2',
    color: 'white',
    paddingTop: '5px',
    fontWeight: '900',
    display: 'inline',
    marginLeft: '1.5rem',
    padding: '0 2px',
    fontSize: '1.1em',
    fontFamily: '"EuclidSquare Regular", sans-serif'
  },
  menuActive: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#0064b2',
    color: '#04bbee',
    paddingTop: '5px',
    fontWeight: '900',
    display: 'inline',
    marginLeft: '1.5rem',
    padding: '2px',
    fontSize: '1.1em',
    fontFamily: '"EuclidSquare Regular", sans-serif'
  },
  subMenu: {
    marginLeft: '2.5rem',
    marginTop: '1.6rem',
    marginRight: '55px',
    fontWeight: '300'
  },
  button: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#0064b2',
    color: 'white',
    position: 'absolute',
    right: '20px',
    paddingTop: '5px'
  },
  btnAccordionDown: {
    content: '',
    border: '2px solid #fff',
    width: '12px',
    height: '12px',
    borderBottom: 'none',
    borderLeft: 'none',
    transform: 'rotate(135deg)',
    float: 'left',
    borderColor: '#04bbee'
  },
  btnAccordionRight: {
    content: '',
    border: '2px solid #fff',
    width: '12px',
    height: '12px',
    borderBottom: 'none',
    borderLeft: 'none',
    transform: 'rotate(45deg)',
    float: 'left',
    marginTop: '0.2rem',
  },
}


class SidebarNavigation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      arrow: false,
      menu: []
    };
  }

  showSub(index) {
    const menu = this.state.menu
    menu[index].showSub = !menu[index].showSub
    this.setState({ menu })
  }


  logout(e){
    e.preventDefault();
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    const lang = getLanguage()
    return <Redirect to={`/${lang}/auth/login`} />
  };

  componentDidMount() {

    const urlPath = window.location.pathname.split("/")
    let menuFiltered = this.props.menu

    menuFiltered.map((child, index) => {
      if (!child.sidebar) {
        menuFiltered.splice(index, 1);
      }
      return null
    })

    menuFiltered.map((child, index) => {
      menuFiltered[index].showSub = (urlPath[2] === child.path.split("/")[2]) ? true : false
      return null
    })
    this.setState({ menu: menuFiltered })

    // mobile trigger
    document.getElementById('navbar-mobile-trigger').onclick=function(){
      let sidebar = document.getElementById('sidebar');
      this.classList.toggle('is-active');
      sidebar.classList.toggle('is-active');
    }

  }


  render() {
    // const { isAuthenticated } = authentication;
    // const lang = getLanguage()
    if (!this.state.menu || this.state.menu.length === 0) {
      return <p style={{textAlign:'center', margin: '100px'}}>Loading ...</p>;
    }

    return (
      <div className="dd-page-sidebar dd-no-print">
        <nav id="sidebar" className={this.props.toggleState} style={styles.sidebar} >

          <ul className="list-unstyled components">
            {
              this.state.menu.map((child, index) => {
                let className = (child.classActive) ? "active" : ""
                return (
                  (child && child.sidebar &&
                  <li key={index} className={className + "mb-5"}>

                  {
                    (child.subMenu.length > 0) ?
                      <button style={(child.showSub) ? styles.menuActive : styles.menu } onClick={ () => this.showSub(index) }>{ child.title }</button>
                    :
                    <NavLink
                      to={child.path}
                      style={styles.menuLink}
                    >
                      {child.title}
                    </NavLink>
                  }

                    <button style={styles.button} onClick={ () => this.showSub(index) }>{ (child.showSub) ? <div style={styles.btnAccordionDown} /> : <div style={styles.btnAccordionRight} /> }</button>
                    {
                      (child && child.showSub) &&
                      child.subMenu.map((item, index) => {
                        return (
                          <NavLink
                            key={index}
                            to={item.path}
                            style={styles.subMenu}
                            activeStyle={{
                              color: "white"
                            }}
                          >
                            {item.title}
                          </NavLink>
                          )
                      })
                    }
                  </li>
                  )
                )
              })
            }
            {
              // isAuthenticated() &&
              // <li>
              //     <a className="pl-4" href={`/${lang}/auth/login`} onClick={(e) => this.logout(e)}>Logout</a>
              // </li>
            }
            {
              // !isAuthenticated() &&
              // <li>
              //     <a className="pl-4" href={`/${lang}/auth/login`}>Login</a>
              // </li>
            }
          </ul>
        </nav>
      </div>
    );
  }
}


export default SidebarNavigation;