import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'

import '../../../node_modules/font-awesome/css/font-awesome.min.css';

import { translate } from '../../helpers';

class DownloadsWidget extends Component {

  addToCart = (item) => {
    this.props.addToCart(item)
  }


  render() {
    configureAnchors({offset: -150, scrollDuration: 600})

    const data = this.props.data || []

    const hasHeader = this.props.hasHeader
    const noHighlights = this.props.noHighlights

    const iconStyle = {
      height: '14px'
    }

    let noHighlightsClass = ""
    if (noHighlights) {
      noHighlightsClass = "dd-table__no-highlights"
    }

    const cartCartIds = []
    if (this.props.cart && Array.isArray(this.props.cart) && (this.props.cart.length > 0)) {
      this.props.cart.map(data => {
        cartCartIds.push(data.cartId)
        return null
      })
    }

    return (
      <div className="table-responsive mb-8 dd-block--table dd-block--downloads" style={{
        "borderTop": "3px solid #0064b3",
        "borderBottom": "3px solid #0064b3"
      }} >
        <ScrollableAnchor id={this.props.id}>
        <table className={`table dd-table js-dd-table dd-table__separated ${this.props.className + " " + noHighlightsClass}`}>
          {hasHeader && (
            <thead>
              <tr>
                <th scope="col" className="col-50">{translate("global", "name")}</th>
                <th scope="col">{translate("global", "typ")}</th>
                <th scope="col">{translate("global", "size")}</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((item, index) => {
              return <tr key={index} >
                <th scope="row">{item.title}</th>
                {typeof item.fileType !== undefined && (<td>{item.fileType}</td>)}
                <td>{item.fileSize}</td>
                <td>
                  <div className="font-icon-detail">
                    <span title="Download" data-toggle="tooltip" data-placement="bottom">
                      <a href={item.file} target="_blank" rel="noopener noreferrer">
                        <svg style={iconStyle} height="18" viewBox="0 0 64 56" width="18" xmlns="http://www.w3.org/2000/svg"><g fillRule="evenodd" transform="translate(10)"><path d="m14.9553483 0v23.3333624h-10.9553483l9.2500317 9.3333188 9.2497783 9.3333188 9.2501583-9.3333188 9.2500317-9.3333188h-10.955095v-23.3333624z" /><path d="m37.7029062 42v6.999875h-30.40555716v-6.999875h-7.29734904v14h3.64848308 3.64886596 30.40555716 3.6486107 3.6484831v-14z" /></g></svg>
                      </a>
                    </span>
                    {' '}
                    {item.cartId && !(cartCartIds.includes(item.cartId)) && (
                      <span onClick={() => this.addToCart(item)} data-toggle="tooltip" data-placement="bottom" title={translate('cart', 'add_to_cart')}>
                        <svg height="18" viewBox="0 0 64 56" width="18" xmlns="http://www.w3.org/2000/svg">
                          <g fillRule="evenodd" transform="translate(4)">
                            <path d="m24 0h8v56h-8z" /><path d="m24 0h8v56h-8z" transform="matrix(0 1 -1 0 56 0)" />
                          </g>
                        </svg>
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
        </ScrollableAnchor>
      </div>
    )
  }
}
export default DownloadsWidget