import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux'
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from './Data/actions/actions';
import { history, store } from './Data/Store';

import Front from './Layout/Front'
import NotFound from './Layout/NotFound'

import Cart from './Components/Pages/Cart'
import ImageDetail from './Components/Pages/Image/ImageDetail'
import Page from './Components/Pages/Page'

import Login from './Components/Pages/Auth/Login';
import Register from './Components/Pages/Auth/Register';
import ForgotPassword from './Components/Pages/Auth/ForgotPassword';
import NewPassword from './Components/Pages/Auth/NewPassword';
import Profile from './Components/Pages/Auth/Profile';

import Contact from './Components/Pages/Contact';

import Search from './Components/Pages/Search';
import BestPractices from './Components/Pages/Overlay/BestPractices';

import { setLocalizationByHost } from './config';
import { loadAPIData, checkLoginState } from './helpers';


class App extends Component {



  renderRoutes = () => {

    if (!this.props.config.data || Object.keys(this.props.config.data).length === 0) {

      return (
        <Switch>
          <Route exact path='/:lang/auth/register' render={(props) => <Register {...this.props} />} />
          <Route exact path='/:lang/auth/login' render={(props) => <Login {...this.props} />} />
          <Route exact path='/:lang/auth/forgot-password' render={(props) => <ForgotPassword {...this.props} />} />
          <Route exact path='/:lang/auth/new-password/:resetToken' render={(props) => <NewPassword {...this.props} />} />
          <Route exact path='/:lang/contact' render={(props) => <Contact {...this.props} />} />
        </Switch>
        )
    }

    return (
      <TransitionGroup>
        <CSSTransition
          key={window.location.pathname}
          classNames="fade"
          timeout={500}
        >
          <Switch>
            <Route exact path='/:lang/image/:imageID' render={(props) => <ImageDetail {...this.props} />} />
            <Route exact path='/:lang/cart' render={(props) => <Cart menu={this.props.config.menu} {...this.props} />} />
            <Route exact path='/:lang/search' render={(props) => <Search menu={this.props.config.menu} {...this.props} />} />
            <Route exact path='/:lang/auth/register' render={(props) => <Register {...this.props} />} />
            <Route exact path='/:lang/auth/login' render={(props) => <Login {...this.props} />} />
            <Route exact path='/:lang/auth/forgot-password' render={(props) => <ForgotPassword {...this.props} />} />
            <Route exact path='/:lang/auth/new-password/:resetToken' render={(props) => <NewPassword {...this.props} />} />
            <Route exact path='/:lang/auth/profile' render={(props) => <Profile menu={this.props.config.menu} {...this.props} />} />
            <Route exact path='/:lang/contact' render={(props) => <Contact {...this.props} />} />
            <Route path='/:lang/best-practices' render={(props) => <BestPractices {...this.props} />} />

            {
              this.props.config.data.map((child, index) => {
                switch (child.component) {
                  case "page":
                    return (
                      <Route key={index} exact path={child.path} render={(props) => <Page data={child} menu={this.props.config.menu} {...this.props}  />} />
                    )
                  default:
                    break;
                }
                return null
              })
            }
            <Route render={(props) => <NotFound {...this.props} menu={this.props.config.menu} />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    )
  }

  async componentDidMount() {
    setLocalizationByHost();

    this.props.setLoggedInState(await checkLoginState())

    try {
      const data = await loadAPIData()

      if (data.data.length !== 0) {
        if (!window.location.pathname.includes('best-practices')) {
          await this.props.setBestPrcaticeState(false)
        }

        await this.props.saveData(data)
        this.renderRoutes()

      } else {
        await this.props.setLoggedInState(false)
        await this.props.saveData([])
        if (!window.location.pathname.includes('auth/')) {
          await store.dispatch(actionCreators.goToLogin())
        }
      }
    }
    catch (err) {
      await this.props.setLoggedInState(false)
      await this.props.saveData([])
      await store.dispatch(actionCreators.goToLogin())
      console.log(err)
    }
  }

  render() {

    return (
      <ConnectedRouter history={history}>
        <Front {...this.props}>
          {
            this.renderRoutes()
          }
        </Front>
      </ConnectedRouter>
    )
  }
}

function mapStateToProps(state) {
  return {
    config: state.config,
    cart: state.cart,
  }
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps, null, { pure: false })(App);
