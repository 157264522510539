import React from 'react';

import {filterHTML} from '../../helpers'

function Image (props) {
    const imageAlt = props.caption.replace(/(<([^>]+)>)/ig,"");
  return (


    <div className={props.className}>
      <img src={props.src} className={props.classImage} alt={imageAlt} width={props.width} height={props.height} />
        {props.caption &&
          <div className="dd-image__caption" dangerouslySetInnerHTML={{ __html: filterHTML(props.caption) }} />
        }
    </div>
    )
}

export default Image