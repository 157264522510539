
import { push } from 'react-router-redux'
import { getLanguage } from '../../config'
import { loadAPIData } from '../../helpers'

export function goHome() {
  return function (dispatch) {
    dispatch(push("/" + getLanguage()));
  }
}

export function goToLogin() {
  return function (dispatch) {
    const path = '/' + getLanguage() + "/auth/login"
    dispatch(push(path));
  }
}

export function loadData() {
  return function (dispatch) {
    return loadAPIData().then(data => {
      dispatch(loadDataSuccess(data))
    })
  }
}

export function loadDataSuccess(data) {
  return { type: 'LOAD_DATA_SUCCESS', data };
}

export function saveData(data) {
  return function (dispatch) {
    dispatch(saveDataSuccess(data))
  }
}

export function saveDataSuccess(data) {
  return { type: 'LOAD_DATA_SUCCESS', data }
}

export function initCart(cart) {
  return function (dispatch) {
    dispatch(initCartSuccess(cart))
  }
}

export function initCartSuccess(cart) {
  return { type: 'INIT_CART_SUCCESS', cart };
}

export function addToCart(item) {
  return function (dispatch) {
    dispatch(updateItemSuccess(item))
  };
}

export function updateItemSuccess(item) {
  return { type: 'ADD_ITEM_SUCCESS', item };
}

export function deleteFromCart(item, index) {
  return function (dispatch) {
    dispatch(deleteFromCartSuccess(item, index))
  };
}

export function deleteFromCartSuccess(item, index) {
  return { type: 'DELETE_ITEM_SUCCESS', item, index };
}

export function loginUser() {
  return function (dispatch) {
    dispatch(loginUserSuccess())
  }
}

export function loginUserSuccess() {
  return { type: 'LOGIN_USER_SUCCESS' }
}

export function setLoggedInState(state) {
  return function (dispatch) {
    dispatch(setLoggedInStateSuccess(state))
  }
}

export function setLoggedInStateSuccess(state) {
  return { type: 'LOGGED_IN_SUCCESS', state }
}


export function setBestPrcaticeState(state) {
  return function (dispatch) {
    dispatch(setBestPrcaticeStateSuccess(state))
  }
}

export function setBestPrcaticeStateSuccess(state) {
  return { type: 'BEST_PRACTICE_SUCCESS', state }
}

export function setLoadingState(state) {
  return function (dispatch) {
    dispatch(setLoadingStateSuccess(state))
  }
}

export function setLoadingStateSuccess(state) {
  return { type: 'SET_LOADING_SUCCESS', state }
}

export function saveContent(data) {
  return function (dispatch) {
    dispatch(saveContentSuccess(data))
  }
}

export function saveContentSuccess(data) {
  return { type: 'SAVE_CONTENT_SUCCESS', data }
}

export function saveMenu(menu) {
  return function (dispatch) {
    dispatch(saveMenuSuccess(menu))
  }
}

export function saveMenuSuccess(menu) {
  return { type: 'SAVE_MENU_SUCCESS', menu }
}

export function saveFooter(footer) {
  return function (dispatch) {
    dispatch(saveFooterSuccess(footer))
  }
}

export function saveFooterSuccess(footer) {
  return { type: 'SAVE_FOOTER_SUCCESS', footer }
}

