import React from 'react';
import ColorCircle from './ColorCircle';

import {sanitizeHTML} from '../../../helpers'

const ColorListItem = (props) => {
  const className = (props.className) ? props.className : ''
  return (
    <div className={className}>
      <ColorCircle hex={props.hex} diameter={props.diameter}/><p/>
      <div className="card-title h5 text text-blue">{props.name}</div>
      <div className="text" dangerouslySetInnerHTML={{__html: sanitizeHTML(props.text)}}></div>
    </div>
  )
}
export default ColorListItem
