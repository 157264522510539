import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha"

import SidebarNavigation from '../../Layout/SidebarNavigation'
//import axios from 'axios'

import { getContactUrl, getDepartmentsUrl } from '../../config'

import { translate } from '../../helpers'
import { getLanguage } from '../../config'

import Alert from '../../Components/Modules/Alert'

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios");


class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        contactPerson: '',
        salutation: '',
        title: '',
        firstname: '',
        lastname: '',
        company: '',
        department: '',
        address: '',
        zipcode: '',
        city: '',
        country: '',
        email: '',
        phone: '',
        fax: '',
        message: '',
        copy: false,
        policy: false
      },
      formErrors: { contactPerson: '', salutation: '', firstname: '', lastname: '', address: '', zipcode: '', city: '', country: '',  email: '', phone: '', message: '' },
      formValid: false,
      submitButtonDisabled: true,
      captcha: '',
      hasError: false,
      errorMessage: '',
      errorType: 'danger',
      errorHeading: '',
      departments: [],
    }
  }

  handleHide = () => this.setState({ hasError: false })

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const user = { ...this.state.user };
    user[name] = value;
    this.setState({ user: user }, () => { this.validateField(name, value) });
  }

  handleSelectChange = (event) => {
    const name = event.target.id
    const value = this.refs.contactPerson.value
    const user = { ...this.state.user };
    user[name] = value
    this.setState({ user }, () => { this.validateField(name, value) });
    return
  }

  onChange = (captcha) => {
    this.setState({ captcha }, this.validateForm )
  }

  validateField = (fieldName, value) => {
    const formErrors = this.state.formErrors;
    const notEmpty = ['contactPerson', 'salutation', 'firstname', 'lastname', 'address', 'zipcode', 'city', 'country', ' email', 'phone', 'message']

    if (notEmpty.includes(fieldName)) {
      formErrors[fieldName] = value.length !== 0 ? '' : translate("auth","fill_this_field");
    }

    this.setState({
      formErrors: formErrors,
    }, this.validateForm);
  }

  validateForm = () => {
    let valide = Object.values(this.state.formErrors).filter((v) => (v !== "")).length === 0
    valide = (this.state.captcha !== '') ? valide : false
    this.setState( { formValid: valide })
  }

  fieldIsValide = (key) => {
    return (this.state.formErrors[key] === '')
  }

  renderError = (key) => {
    if (this.state.formErrors[key] !== '') {
      return (<div className="invalid-feedback">
        {this.state.formErrors[key]}
              </div>)
    }
    return ''
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData();
    data.append("contactPerson", this.state.user.contactPerson)
    data.append("salutation", this.state.user.salutation)
    data.append("title", this.state.user.title)
    data.append("firstname", this.state.user.firstname)
    data.append("lastname", this.state.user.lastname)
    data.append("company", this.state.user.company)
    data.append("department", this.state.user.department)
    data.append("address", this.state.user.address)
    data.append("zipcode", this.state.user.zipcode)
    data.append("city", this.state.user.city)
    data.append("country", this.state.user.country)
    data.append("mail", this.state.user.email)
    data.append("phone", this.state.user.phone)
    data.append("fax", this.state.user.fax)
    data.append("message", this.state.user.message)
    data.append("copy", this.state.user.policy)

    axios.post(getContactUrl(), data)
    .then(response => {
      this.setState({ hasError: true, errorMessage: "", errorType: "success", errorHeading: translate("contact", "success") })
    })
    .catch(err => {
      console.log(err.response)
      this.setState({ hasError: true, errorMessage: err.response.data, errorHeading: translate("auth", "error") })
    })
  }

  loadData = async () => {
    axios.get(getDepartmentsUrl())
    .then(response => {
      const departments = response.data
      this.setState({ departments })
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    const lang = getLanguage()
    return (
      <React.Fragment>
        <div className="dd-page-container" id="dd-page-container">
          { this.props.config.menu &&
            <SidebarNavigation menu={this.props.config.menu} />
          }
          <div className="container">
            <section className="content-section" id="login-page">
              {
                this.state.hasError &&
                <div className="container mb-6 px-0">
                  <Alert
                    show={this.state.hasError}
                    text={this.state.errorMessage}
                    type={this.state.errorType}
                    heading={this.state.errorHeading}
                    handleHide={this.handleHide}
                    />
                </div>
              }
              <form onSubmit={(e) => this.handleSubmit(e)}>
              <div className="dd-auth-form">
                <div className="container bg-primary text-white">
                  <div className="row py-5">
                    <div className="col-lg-10 offset-lg-1">

                      <h3 className="display-3 text-white">{translate("contact","contact")}</h3>

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <div>{translate("contact","direct_contact")}</div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <select ref="contactPerson" type="Unternehmensbereich" className="form-control" id="contactPerson" value={this.state.department} required onChange={this.handleSelectChange}>
                              <option key="null" value="">{translate("contact","your_main_interest")}</option>
                              {
                                this.state.departments.map((child, index) => {
                                  return <option key={index} value={child}>{child}</option>
                                })
                              }
                            </select>
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="salutation" className={this.fieldIsValide("salutation") ? "form-control" : "form-control is-invalid"} name="salutation" id="salutation" placeholder={translate("contact","salutation")} required onChange={this.handleChange}/>
                            {this.renderError("salutation")}
                          </div>

                          <div className="form-group col-md-6">
                            <input type="title" className="form-control" name="title" id="title" placeholder={translate("contact","title")} onChange={this.handleChange}/>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="firstname" className={this.fieldIsValide("firstname") ? "form-control" : "form-control is-invalid"} name="firstname" id="firstname" placeholder={translate("register","pre_name")} required onChange={this.handleChange}/>
                            {this.renderError("firstname")}
                          </div>

                          <div className="form-group col-md-6">
                            <input type="lastname" className={this.fieldIsValide("lastname") ? "form-control" : "form-control is-invalid"} name="lastname" id="lastname" placeholder={translate("register","name")} required onChange={this.handleChange}/>
                            {this.renderError("lastname")}
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="company" className="form-control" name="company" id="company" placeholder={translate("contact","company")} onChange={this.handleChange}/>
                          </div>

                          <div className="form-group col-md-6">
                            <input type="department" className="form-control" name="department" placeholder={translate("contact","department")} onChange={this.handleChange}/>
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <input type="address" className={this.fieldIsValide("address") ? "form-control" : "form-control is-invalid"} name="address" placeholder={translate("contact","address")} required onChange={this.handleChange}/>
                            {this.renderError("address")}
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="zipcode" className={this.fieldIsValide("zipcode") ? "form-control" : "form-control is-invalid"} name="zipcode" placeholder={translate("contact","zipcode")} required onChange={this.handleChange}/>
                            {this.renderError("zipcode")}
                          </div>

                          <div className="form-group col-md-6">
                            <input type="city" className={this.fieldIsValide("city") ? "form-control" : "form-control is-invalid"} name="city" id="city" placeholder={translate("contact","city")} required onChange={this.handleChange}/>
                            {this.renderError("city")}
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="country" className={this.fieldIsValide("country") ? "form-control" : "form-control is-invalid"} name="country" placeholder={translate("contact","country")} required onChange={this.handleChange}/>
                            {this.renderError("country")}
                          </div>

                          <div className="form-group col-md-6">
                            <input type="email" className={this.fieldIsValide("email") ? "form-control" : "form-control is-invalid"} name="email" id="email" placeholder={translate("contact","email")} required onChange={this.handleChange}/>
                            {this.renderError("email")}
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="phone" className={this.fieldIsValide("phone") ? "form-control" : "form-control is-invalid"} name="phone" placeholder={translate("contact","phone")} required onChange={this.handleChange}/>
                            {this.renderError("phone")}
                          </div>

                          <div className="form-group col-md-6">
                            <input type="fax" className="form-control" name="fax" id="fax" placeholder={translate("contact","fax")} onChange={this.handleChange}/>
                          </div>
                        </div>


                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <textarea type="textarea" className={this.fieldIsValide("message") ? "form-control" : "form-control is-invalid"} name="message" placeholder={translate("contact","message")} rows="5" required onChange={this.handleChange} />
                            {this.renderError("message")}
                          </div>
                        </div>


                        <div className="form-check mb-4">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" name="copy" id="copy" value="1" onChange={this.handleChange} />
                            {translate("contact","copy_of_message")}
                          </label>
                        </div>

                        <div className="form-check form-check-inline mb-4">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" name="policy" id="policy" value="1" required onChange={this.handleChange} />
                            {translate("contact","data_protection")}&nbsp;&nbsp;
                            <Link to={`/${lang}/datenschutz`} style={{textDecoration:'underline'}}>
                              {translate("contact","privacy_policy")}
                            </Link>
                          </label>
                        </div>

                        <ReCAPTCHA
                          sitekey="6LdtPEsUAAAAACD8UD0FEtpuSd6qSeQpBhMX3MF6"
                          onChange={this.onChange}
                        />

                        <p />
                        <div className="container mb-5">
                          <div className="form-group row">
                            <button type="submit" className="btn btn-secondary" disabled={!this.state.formValid}>{translate("contact","send_mail")}</button>
                          </div>
                        </div>

                        <p> <span className="dd-form-asterisk">*</span> {translate("auth","required")}</p>
                        <a href={`/${lang}/auth/login`}>{translate("auth","back_to_login")}</a>
                    </div>
                  </div>
                </div>
              </div>
              </form>
            </section>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default withRouter(Contact)


