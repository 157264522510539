import React from 'react'
import { Link } from 'react-router-dom'

import { translate } from '../../helpers';

const ButtonLink = props => {
  return (
    <Link
      className="btn btn-primary btn-outline-light float-right"
      to={props.href}
      role="button"
    >{translate("modules","all_stories")}</Link>
  )
}


export default ButtonLink
