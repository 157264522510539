import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { translate } from '../../../helpers';

class FeatureLinks extends Component {

  render() {

    const styles = {
      colorBright: {
        backgroundColor: '#0064B1',
      },
      feature: {
        backgroundImage: `url(${this.props.image})`,
      },
    }

    return (
      <div href="/home" className="dd-feature dd-feature--textonly js-dd-feature">
        <div className="dd-feature__content" style={styles.colorBright}>
          <div className="dd-feature__content-wrapper">
            <h3 className="dd-feature__title">
              {this.props.title}
            </h3>
            <hr />
            {this.props.link && (this.props.link.length > 0) &&
              this.props.link.map((child, index) => {
                return <React.Fragment key={index}>
                  <a href={child['path']} style={{ color: 'white', fontWeight: "bold" }}>{child['linktitle']}</a>
                  <hr />
                </React.Fragment>
              })
            }
            <p>
              {this.props.text}
            </p>
          </div>
          <div className="dd-feature__link">
            <Link to={this.props.href}>
              <button className="btn btn-secondary">
                {translate("feature","all_downloads")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default FeatureLinks