import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// to make it work in IE
import 'airbnb-js-shims';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from './serviceWorker';

import { store, persistor } from './Data/Store';
import App from './App';


import "./assets/fontawesome-free/css/all.min.css";
import './index.css';

// we import bootstrap in sass
import './assets/sass/main.sass'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();