import React from 'react';
import Alert from 'react-bootstrap/Alert'

class AlertDismissible extends React.Component {

  onClose() {
    console.log()
  }

  render() {
    const type = this.props.type || "danger"
    const heading = this.props.heading

    return (
      <React.Fragment>
        <Alert show={this.props.show} variant={type} onClose={this.onClose}>
          <div className="float-right">
            <button onClick={this.props.handleHide} type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true" className={'text-' + type}>&times;</span>
            </button>
          </div>
          <Alert.Heading>{heading}</Alert.Heading>
          <p>
            {this.props.text}
          </p>
        </Alert>

      </React.Fragment>
    );
  }
}

export default AlertDismissible