import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SidebarNavigation from '../../Layout/SidebarNavigation'

import axios from 'axios'
import qs from 'query-string'

import { Row, Col } from 'react-bootstrap';

import SearchWidget from '../Modules/Search/SearchWidget'
import SearchResultItem from '../Modules/Search/SearchResultItem'
import FilterOptions from '../Modules/Filter/FilterOptions'
import Loading from '../../Layout/Loading'


import { translate } from '../../helpers';
import { getSearchUrl, getFileSearchUrl, getLanguage } from '../../config';
import { history } from '../../Data/Store';

const styles = {
  colorBlue: {
    color: '#0064b2',
  },
  spacing: {
    paddingBottom: '80px',
  },
  button: {
    border: 'none',
    outline: 'none',
    paddingLeft: '0',
  },
  filterOptionen: {
    margin: '0.7em',
    marginLeft: '0',
    display: 'inline',
  },
  btnAccordionDown: {
    content: '',
    marginTop: '0.3em',
    border: '2px solid #04bbed',
    width: '12px',
    height: '12px',
    borderBottom: 'none',
    borderLeft: 'none',
    transform: 'rotate(135deg)',
    float: 'right',
  },
  btnAccordionRight: {
    content: '',
    marginTop: '0.4em',
    border: '2px solid #04bbed',
    width: '12px',
    height: '12px',
    borderBottom: 'none',
    borderLeft: 'none',
    transform: 'rotate(45deg)',
    float: 'right',
  },
}


class Search extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      openFilter: false,
      data: [],
      files: [],
      page: 1,
      searchTerm: '',
      fileKind: [],
      fileType: [],
      filterTerms: [],
      isLoading: false,
    };
  }

  filterTermClick = (item) => {

    const filterTerms = (this.state.filterTerms) ? this.state.filterTerms : []
    const fileKind = (this.state.fileKind) ? this.state.fileKind : []
    const fileType = (this.state.fileType) ? this.state.fileType : []

    const termToDelete = filterTerms.findIndex(val => val === item);
    filterTerms.splice(termToDelete, 1);

    const itemKindToDelete = fileKind.findIndex(val => val === item);
    fileKind.splice(itemKindToDelete, 1);

    const itemTypeToDelete = fileType.findIndex(val => val === item);
    fileType.splice(itemTypeToDelete, 1);

    this.setState({ filterTerms, fileType, fileKind }, () => {
      this.doSearch()
    })
  }

  toggleFilter() {
    const openFilter = !this.state.openFilter
    this.setState( { openFilter } )
  }

  handleSelectChange = (event, selector) => {
    let value = event.value
    if (Array.isArray(event)) {
      value = event
        .map(k => k.value)
        .join(',')
    }

    let label = event.label
    if (Array.isArray(event)) {
      label = event
        .map(k => k.label)
        .join(',')
    }

    let filterTerms = (this.state.filterTerms) ? this.state.filterTerms : []

    if (filterTerms.includes(label)) {
      filterTerms = filterTerms.filter(e => e !== label);
    }
    else {
      filterTerms.push(label)
    }

    let fileKind = this.state.fileKind
    if (selector === 'fileKind') {
      if (fileKind.includes(value)) {
        fileKind = fileKind.filter(e => e !== value);
      }
      else {
        fileKind.push(value)
      }
    }

    let fileType = this.state.fileType
    if (selector === 'fileType') {
      if (fileType.includes(value)) {
        fileType = fileType.filter(e => e !== value);
      }
      else {
        fileType.push(value)
      }
    }

    this.setState({ filterTerms, fileKind, fileType }, () => {
      this.doSearch()
    })
  }

  renderSearchResultHeadline = () => {
    if (this.state.searchTerm) {

      let length = 0

      if (this.state.data.length) {
        length = (this.state.data.length) ? this.state.data.length : 0;
      }

      if (this.state.files && this.state.files.results && this.state.files.results.length) {
        length = length + this.state.files.results.length
      }

      if (length !== 0) {

        return `${length} ${translate("search","result_for")} "${this.state.searchTerm}"`

      } else {
        return `${translate("search","no_result_for")} "${this.state.searchTerm}"`
      }
    }
  }

  doSearch = async () => {
    this.setState({ isLoading: true });

    const params = {
      searchTerm: this.state.searchTerm,
      fileKind: this.state.fileKind,
      fileType: this.state.fileType
    }

    const query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&')

    try {
      const searchResponse = await axios(`${getSearchUrl()}?${query}`,{ withCredentials: true })
      const searchData = await searchResponse.data

      const filesResponse = await axios(`${getFileSearchUrl()}?${query}`, { withCredentials: true })
      const fileshData = await filesResponse.data
      this.setState({ data: searchData, files: fileshData, isLoading: false})

    } catch (error) {
      const lang = getLanguage()
      history.push(`/${lang}`);
    }

  }

  handleSearch = (term) => {
    this.setState({ searchTerm: term }, () => {
      this.doSearch()
    })
  }

  buildFilterOptions = () => {

    const files = this.state.files

    if (!files.results) {
      return []
    }

    const fileKinds = []
    if (files.filter.fileKinds) {
      files.filter.fileKinds.forEach(item =>{
        fileKinds.push({ value: item.type, label: `${item.type} (${item.counter})`})
      } )
    }

    const fileTypes = []
    if (files.filter.fileTypes) {
      files.filter.fileTypes.forEach(item =>{
        fileTypes.push({ value: item.type, label: `${item.type} (${item.counter})` })
      } )
    }

    return [
      [
        {
          filterType: 'fileKind',
          label: 'Dateiart',
          values: fileKinds
        },
      ],
      [
        {
          filterType: 'fileType',
          label: 'Dateityp',
          values: fileTypes
        },
      ],
    ]
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search)
    if (params && params.searchTerm) {
      this.setState({ searchTerm: params.searchTerm }, () => {
        this.doSearch()
      })
    }
  }

  render() {
    const searchableLookupItems = this.state.data

    const files = (Array.isArray(this.state.files.results)) ? this.state.files.results : []

    const showFilter = (files.length > 0)

    const filterOptions = this.buildFilterOptions()

    const btnAccordionAfter = (this.state.openFilter) ? styles.btnAccordionDown : styles.btnAccordionRight

    return (
      <div className="dd-page-container" id="dd-page-container">
          { this.props.menu &&
            <SidebarNavigation menu={this.props.menu} />
          }

      <div className="container">
        <section className="content-section">
          <div className="content-section-heading">
            <h2 className="mb-5" style={styles.colorBlue} >{translate("search","search_result")}</h2>
          </div>
          <div className="mb-5">
            <SearchWidget searchCallback={this.handleSearch} searchTerm={this.state.searchTerm}/>
          </div>
          <div className="dd-loading__content-wrapper">
            {
              (showFilter) &&
                <Row>
                  <Col xs={12} md={8} className="mb-5">
                    <button type="button" style={styles.button} onClick={() => this.toggleFilter()} >
                      <div style={styles.filterOptionen} >{translate("search","filter_options")}</div>
                      <div style={btnAccordionAfter} />
                    </button>
                  </Col>
                </Row>
            }

            { this.state.openFilter &&
              <FilterOptions
                filterOptions={filterOptions}
                filterTerms={this.state.filterTerms}
                filterTermClick={this.filterTermClick}
                handleSelectChange={this.handleSelectChange}
              />
            }

            <Row style={styles.spacing}>
              <Col xs={12} md={10}>
                <h2 style={styles.colorBlue} >{this.renderSearchResultHeadline()}</h2>
              </Col>
            </Row>

              {
                searchableLookupItems.map((child, index) => {
                  return <SearchResultItem key={index} data={child} />
                })
              }

              {
                files.map((child, index) => {
                  return <SearchResultItem key={index} data={child} />
                })
              }


            <Loading showLoading={this.state.isLoading} />
          </div>
        </section>
      </div>
      </div>
    );
  }
}
export default withRouter(Search)