import React from 'react';
import { withRouter } from 'react-router-dom';
import { history } from './../Data/Store';

import SidebarNavigation from './SidebarNavigation'

class NotFound extends React.Component {

  componentDidMount() {
    if (this.props.config.data && Object.keys(this.props.config.data).length > 0) {
      const firstPage = this.props.config.data[0]
      history.push(firstPage.path)
    }
  }


  render() {

    return (
      <React.Fragment>
        <div className="dd-page-container" id="dd-page-container">

          {this.props.menu &&
            <SidebarNavigation menu={this.props.menu} />
          }

          <div className="dd-page-content">
            <div className="container">
              <div>
                  <section className="content-section">
                  <section className="dd-intro js-dd-intro container dd-section--padding mb-5">
                    <div className="row">
                      <div>
                        <div className="dd-intro__content">
                          <h1 className="dd-intro__headline">Page not found</h1>
                        </div>
                      </div>
                    </div>
                  </section>
                  </section>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(NotFound);