import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'

const initialState = {
  config: {
    logged_in: false,
    loading: false,
    bestPractiseVisible: false,
    data: [],
    menu: [],
    footer: []
  },
  cart: []
}

export function config(state = initialState.config, action) {
  switch (action.type) {
    case 'LOGIN_USER_SUCCESS':
      return  {
        ...state,
        logged_in: true
      }
    case 'SET_LOADING_SUCCESS':
      return {
        ...state,
        loading: action.state
      }
    case 'LOGGED_IN_SUCCESS':
      return  {
        ...state,
        logged_in: action.state
      }
    case 'BEST_PRACTICE_SUCCESS':
      return  {
        ...state,
        bestPractiseVisible: action.state
      }
    case 'SAVE_CONTENT_SUCCESS':
      return  {
        ...state,
        data: action.data
      }
    case 'SAVE_MENU_SUCCESS':
      return {
        ...state,
        menu: action.menu
      }
    case 'SAVE_FOOTER_SUCCESS':
      return {
        ...state,
        footer: action.footer
      }
    case 'LOAD_DATA_SUCCESS':
      return {
        ...state,
        footer: action.data.footer,
        menu: action.data.menu,
        data: action.data.data
      }
    default:
      return state
  }
}

export function cart(state = initialState.cart, action) {
  switch (action.type) {
    case 'INIT_CART_SUCCESS':
      return [
      ]
    case 'ADD_ITEM_SUCCESS':
      return [
        ...state,
        action.item
      ]
    case 'DELETE_ITEM_SUCCESS':
      const newState = Object.assign([], state);
      newState.splice(action.index, 1);
      return newState;
    default:
      return state
  }
}

const rootReducer = combineReducers({
  config,
  cart,
  router: routerReducer
});

export default rootReducer;