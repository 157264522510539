import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import rootReducer from './reducers/reducers';

const persistConfig = {
  key: 'brand-portal',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const history = createBrowserHistory()

const middleware = [
 thunk,
 routerMiddleware(history)
]

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      ...middleware
    ),
  )
)

const persistor = persistStore(store);

export { store, persistor }