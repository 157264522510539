import React from 'react';

// Porps Syntax [{title: 'Images', path: '/images'}, {title: 'Image', path: null}]

class Breadcrumb extends React.Component {

  renderLinks = (items) => {
    const links = []

    items.forEach((item, index) => {
      if (!item.path) {
        links.push(
          <li key={index}>{item.title}</li>
        )
      } else {
        links.push(
          <li key={index}><a href={item.path}>{item.title}</a></li>
        )
      }
    })

    return links
  }

  render() {

    return (
      <section className="dd-breadcrumb">
          <div className="row">
            <div className="col-12">
              <ul>
                { (this.props.items) && (this.props.items.length > 0) &&
                  this.renderLinks(this.props.items)
                }
              </ul>
            </div>
          </div>
      </section>
    )
  }
}
export default Breadcrumb
