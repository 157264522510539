import React, { Component } from 'react';


class Grouping extends Component {

  render () {

    return (
      <div className={this.props.className}>
      {
        this.props.content.map((child, index) => {
          return (
            <React.Fragment key={index}>
              {child}
            </React.Fragment>
          )
        })
      }
      </div>
    )
  }
}

export default Grouping