import React, { Component } from 'react';

class HeroVideo extends Component {
  render() {

    const url = (this.props.url) ? this.props.url : 'https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4'
    const title = (this.props.title) ? this.props.title : ''
    const text = (this.props.text) ? this.props.text : ''
    //const playSvg = require('../../assets/svg-icons/play.svg');

    return (
      <header className="dd-hero">
        <div className="dd-hero__inner">
          <div className="overlay"></div>
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
            <source src={url} type="video/mp4" />
          </video>
          <div className="container h-100 dd-hero__container">
            <div className="col offset-xl-2 dd-hero__content">
              <h2 className="dd-hero__title">
                {title}
              </h2>
            </div>
          </div>

          <a href="#dd-page-container" className="dd-hero__arrow"><span>{text}</span></a>
        </div>
      </header>
    )
  }
}
export default HeroVideo