import React from "react";

class Loading extends React.Component {
  render() {
    const showLoading = this.props.showLoading
    if (!showLoading) {
      return <React.Fragment></React.Fragment>
    }

    return (
      <div className="dd-loading__wrapper">
        <div className="dd-loading__spinner">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="411.5px" height="411.5px" viewBox="0 0 411.5 411.5" enableBackground="new 0 0 411.5 411.5">
          <g>
            <g className="dd-loading__circles">
              <path fill="#0064B3" d="M219.25,49.339c-0.025,7.457-6.09,13.48-13.547,13.455c-7.455-0.025-13.478-6.09-13.454-13.547
                c0.027-7.452,6.091-13.479,13.545-13.453C213.254,35.82,219.276,41.886,219.25,49.339z"/>
              <path fill="#0064B3" d="M196.086,58.723c-5.207-5.338-5.101-13.885,0.239-19.091c5.337-5.206,13.884-5.1,19.091,0.242
                c5.199,5.335,5.096,13.882-0.242,19.087C209.836,64.168,201.289,64.057,196.086,58.723z"/>
              <path fill="#0064B3" d="M255.762,67.212c-2.586-6.994,0.988-14.759,7.984-17.343c6.992-2.584,14.758,0.99,17.342,7.988
                c2.58,6.988-0.992,14.753-7.986,17.337C266.108,77.779,258.344,74.201,255.762,67.212z"/>
              <path fill="#0064B3" d="M306.823,99.242c0.482-7.441,6.906-13.08,14.35-12.596c7.439,0.484,13.078,6.908,12.592,14.353
                c-0.484,7.434-6.906,13.073-14.348,12.589S306.34,106.678,306.823,99.242z"/>
              <path fill="#0064B3" d="M340.44,149.273c3.467-6.602,11.631-9.14,18.232-5.67c6.598,3.469,9.137,11.632,5.664,18.234
                c-3.467,6.594-11.627,9.133-18.227,5.664C339.508,164.032,336.973,155.869,340.44,149.273z"/>
              <path fill="#0064B3" d="M350.797,208.653c5.854-4.62,14.342-3.618,18.963,2.238c4.617,5.852,3.613,14.342-2.244,18.961
                c-5.85,4.613-14.336,3.613-18.955-2.24C343.942,221.756,344.948,213.268,350.797,208.653z"/>
              <path fill="#0064B3" d="M336.104,267.112c7.227-1.84,14.574,2.529,16.412,9.758c1.838,7.225-2.531,14.57-9.762,16.408
                c-7.221,1.834-14.566-2.531-16.404-9.758S328.883,268.948,336.104,267.112z"/>
              <path fill="#0064B3" d="M298.901,314.538c7.352,1.258,12.285,8.238,11.025,15.59c-1.262,7.348-8.24,12.281-15.594,11.018
                c-7.342-1.26-12.277-8.238-11.016-15.586C284.577,318.209,291.559,313.278,298.901,314.538z"/>
              <path fill="#0064B3" d="M245.624,342.729c6.203,4.139,7.873,12.523,3.73,18.727c-4.141,6.199-12.523,7.867-18.729,3.721
                c-6.193-4.137-7.863-12.52-3.723-18.719C231.045,340.256,239.428,338.59,245.624,342.729z"/>
              <path fill="#0064B3" d="M185.486,346.809c3.982,6.305,2.097,14.643-4.211,18.625c-6.304,3.979-14.641,2.092-18.622-4.219
                c-3.976-6.299-2.09-14.635,4.214-18.613C173.174,338.62,181.51,340.508,185.486,346.809z"/>
              <path fill="#0064B3" d="M128.889,326.075c1.073,7.379-4.041,14.229-11.423,15.301c-7.376,1.07-14.226-4.045-15.296-11.43
                c-1.069-7.371,4.045-14.219,11.422-15.289C120.973,313.584,127.82,318.7,128.889,326.075z"/>
              <path fill="#0064B3" d="M85.62,284.11c-2.021,7.178-9.479,11.355-16.659,9.33c-7.174-2.023-11.35-9.482-9.323-16.664
                c2.021-7.168,9.479-11.344,16.653-9.32C83.47,269.481,87.645,276.938,85.62,284.11z"/>
              <path fill="#0064B3" d="M63.164,228.172c-4.767,5.734-13.279,6.518-19.014,1.746c-5.73-4.766-6.512-13.277-1.738-19.014
                c4.762-5.727,13.273-6.508,19.004-1.741C67.15,213.934,67.931,222.444,63.164,228.172z"/>
              <path fill="#0064B3" d="M65.404,167.937c-6.688,3.301-14.781,0.553-18.08-6.139c-3.296-6.684-0.547-14.778,6.146-18.076
                c6.68-3.295,14.773-0.546,18.069,6.141C74.838,156.552,72.09,164.643,65.404,167.937z"/>
              <path fill="#0064B3" d="M91.954,113.822c-7.452,0.295-13.728-5.508-14.02-12.963c-0.292-7.446,5.512-13.723,12.968-14.013
                c7.442-0.293,13.718,5.511,14.01,12.96C105.203,107.26,99.401,113.532,91.954,113.822z"/>
              <path fill="#0064B3" d="M138.221,75.188c-6.928-2.762-10.3-10.615-7.534-17.545c2.763-6.921,10.617-10.294,17.547-7.525
                c6.917,2.76,10.29,10.614,7.526,17.537C152.994,74.582,145.142,77.952,138.221,75.188z"/>
            </g>
          </g>
          </svg>
        </div>
      </div>
    )
  }
}

export default Loading;
