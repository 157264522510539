import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';


import SidebarNavigation from '../../Layout/SidebarNavigation'

import DownloadsWidget from '../Modules/DownloadsWidget';
import Table from '../Modules/Table';

import ColorCircle from '../Modules/Color/ColorCircle';
import ColorListItem from '../Modules/Color/ColorListItem';

import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'

import Slider from '../Modules/Slider'
import HeroVideo from '../Modules/HeroVideo'
import ButtonLink from '../Modules/ButtonLink'
import Arrow from '../Modules/Arrow'

import Intro from '../Modules/Text/Intro';
import Headline from '../Modules/Text/Headline';
import Text from '../Modules/Text/Text';
import List from '../Modules/Text/List';

import MediaListView from '../Modules/MediaListView';

import Grouping from '../Modules/Grouping';

import Image from '../Modules/Image';

import Video from '../Modules/Video';

import Feature from '../Modules/Feature/Feature';
import FeatureTextOnly from '../Modules/Feature/FeatureTextOnly';
import FeatureLinks from '../Modules/Feature/FeatureLinks';

import Row from 'react-bootstrap/Row'
import SimpleSlider from '../Modules/Slider/SimpleSilder';
import StorySlider from '../Modules/Slider/StorySlider';

import { translate } from '../../helpers';
import { getLanguage } from '../../config';

class Page extends Component {

  renderModules = (modules) => {

    const out = []

    for (const key of Object.keys(modules)) {

      let item = modules[key]
      let type = item['type']
      let content = ''
      let className = ''

      switch (type) {
        case "hero":
          let hero = <HeroVideo
            url={modules[key].src}
            title={modules[key].title}
            text={modules[key].text}
          />
          out.push(hero)
          break;

        case "mediaView":
          out.push(<MediaListView
            key={key}
            addToCart={this.props.addToCart}
            cart={this.props.cart}
          />)
          break;

        case "intro":
          let intro = <Intro
            key={key}
            headline={modules[key].headline}
            text={modules[key].text}
          />
          out.push(intro)
          break;

        case "coloredline":
          let coloredline = <hr
            key={key}
            style={{
                borderColor: modules[key].color,
            }}
            className={modules[key].class}
          />
          out.push(coloredline)
          break;

        case "colorListItem":
          className = (modules[key].class) ? modules[key].class : ''
          let colorListItem = <ColorListItem
            key={key}
            hex={modules[key].hex}
            diameter={modules[key].diameter}
            name={modules[key].name}
            text={modules[key].text}
            className={className}
          />
          out.push(colorListItem)
          break;

        case "colorCircle":
          className = (modules[key].class) ? modules[key].class : ''
          let colorCircle = <ColorCircle
            key={key}
            diameter={modules[key].diameter}
            hex={modules[key].hex}
            className={className}
          />
          out.push(colorCircle)
          break;

        case "arrow":
          let arrow = <Arrow
            key={key}
            direction={modules[key].direction}
            size={modules[key].size}
          />
          out.push(arrow)
          break;

        case "list":
          className = (modules[key].class) ? modules[key].class : ''
          let list = <List
            key={key}
            data={modules[key].data}
            className={className}
          />
          out.push(list)
          break;

        case "headline":
          const color = (modules[key].color) ? modules[key].color : '#0064b3'
          let headline = <Headline
            key={key}
            heading={modules[key].heading}
            className={modules[key].class}
            text={modules[key].text}
            color={color}
          />
          let headlined = (modules[key].div) ? <div key={key} className="row mb-5 justify-content-md-center col-lg-12">{headline}</div> : headline
          out.push(headlined)
          break;

        case "text":
          className = (modules[key].class) ? modules[key].class : ''
          let textItem = <Text
            key={key}
            className={className}
            text={modules[key].text}
          />
          out.push(textItem)
          break;

        case "feature":
          className = (modules[key].class) ? modules[key].class : ''
          let feature = <div
              key={key}
              className={className}
            >
              <Feature
                title={modules[key].title}
                image={modules[key].image}
                href={modules[key].path}
              />
            </div>
          out.push(feature)
          break;

        case "featureTextOnly":
          className = (modules[key].class) ? modules[key].class : ''
          let featureTextOnly = <div
              key={key}
              className={className}
            >
              <FeatureTextOnly
                title={modules[key].title}
                image={modules[key].image}
                href={modules[key].path}
              />
            </div>
          out.push(featureTextOnly)
          break;


        case "featureLinks":
          className = (modules[key].class) ? modules[key].class : ''
          let featureLinks = <div
              key={key}
              className={className}
            >
              <FeatureLinks
                title={modules[key].title}
                image={modules[key].image}
                href={modules[key].path}
                link={modules[key].link}
              />
            </div>
          out.push(featureLinks)
          break;

        case "image":
          let imageItem = <Image
            key={key}
            src={modules[key].src}
            className={modules[key].classDiv}
            classImage={modules[key].classImage}
            alt={modules[key].caption}
            caption={modules[key].caption}
            width={modules[key].width}
            height={modules[key].height}
          />
          let imaged = (modules[key].div) ? <div key={key} className="row mb-5 no-gutters">{imageItem}</div> : imageItem
          out.push(imaged)
          break;

        case "grouping":
          content = this.renderModules(modules[key].data)
          className = (modules[key].class) ? modules[key].class : ''
          out.push(
            <Grouping
              content={content}
              key={key}
              className={className}
            />
          )
          break;

        case "cardgroup":
          out.push(
            <CardGroup key={key}>
              {
                modules[key].data.map((child, index) => {
                  return (
                    <Card key={index}>
                      <Card.Img variant="top" src={child.src} />
                      <Card.Body>
                        <Card.Title>{child.title}</Card.Title>
                        <Card.Text>{child.text}</Card.Text>
                      </Card.Body>
                    </Card>
                  )
                })
              }
            </CardGroup>
          )
          break;

        case "downloads":
          const downloadsClassName = (modules[key].class) ? modules[key].class : ''
          out.push(
            <DownloadsWidget
              hasHeader={modules[key].hasHeader}
              key={key}
              id={modules[key].id}
              data={modules[key].data}
              className={downloadsClassName}
              addToCart={this.props.addToCart}
              cart={this.props.cart}
            />
          )
          break;

        case "featureRow":
          out.push(
            <Row key={key}>
              {
                modules[key].data.map((child, index) => {
                  return (
                    <div key={index} className={child.class}>
                      <Feature title={child.title} image={child.image} isOverlay={true} href={child.href} />
                    </div>
                  )
                })
              }
            </Row>
          )
          break;

        case "table":
          out.push(
            <Table
              hasHeader={modules[key].hasHeader}
              noOutline={modules[key].noOutline}
              noHighlights={modules[key].noHighlights}
              isCellSeparated={modules[key].isCellSeparated}
              key={key}
              data={modules[key].data}
            />
          )
          break;
        case "video":
          out.push(
            <div key={key} className="dd-block--video mb-3 row justify-content-md-center">
              <div className="col">
                <Video {...modules[key]} />
              </div>
            </div>
          )
          break;

        case "slider":
          out.push(
            <div key={key} className="row mb-5 col-lg-12">
              <Slider anchor={modules[key].anchor} width={modules[key].width} />
            </div>
          )
          break;

        case "buttonLink":
          out.push(
            <ButtonLink
              key={key}
              href={modules[key].href}
              text={modules[key].text}
            />
          )
          break;

        case "articleSlider":
          out.push(
            <div key={key} className="row mb-5 col-lg-12">
              <Slider anchor={modules[key].anchor} width={modules[key].width} />
            </div>
          )
          break;

        case "simpleSlider":
          out.push(
            <SimpleSlider
              key={key}
              content={modules[key].data}
            />
          )
          break;

        case "storySlider":
          out.push(
            <div key={key} className={modules[key].class}>
              <StorySlider key={key}/>
            </div>
          )
          break;

        default:
          break;
      }
    }
    return out
  }


  render() {
    const styles = {
      paddingBottom: '50px',
    }

    if (!this.props.data || this.props.data.length === 0) {
      return '';
      // return <p style={{textAlign:'center', margin: '100px'}}>Loading ...</p>;
    }

    const data = {...this.props.data}

    const dataPath = data.path.split('/')
    const backButton = ((dataPath.length > 3) && (dataPath[2] === 'best-practices' )) ? true : false
    const lang = getLanguage()

    if (typeof data.page_type !== 'undefined' && data.page_type === 'overlay') {
      return (
        <React.Fragment>
          <div className="dd-overlaypage-container">
            <div className="dd-overlaypage-content">
              <div className="container">
                <div className="container-fluid">
                  <section className="content-section">
                    {this.renderModules(data.data)}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {data.heroPage &&
          <HeroVideo
            url={data.heroData.src}
            title={data.heroData.title}
            text={data.heroData.text}
          />
        }

        <div className="dd-page-container" id="dd-page-container">

          { this.props.menu &&
            <SidebarNavigation menu={this.props.menu} />
          }

          <div className="dd-page-content">
            {data.heroContent &&
              <HeroVideo
                url={data.heroData.src}
                title={data.heroData.title}
                text={data.heroData.text}
              />
            }
            <div className="container">
              <div style={styles}>
                {data.data &&
                  <section className="content-section">
                  <div>
                  { backButton &&
                    <Link className="dd-best-practices" to={`/${lang}/best-practices`}>
                      <Arrow
                        direction="left"
                        size="9px"
                        float="left"
                        marginTop="0.5rem"
                        marginRight="0.4rem"
                      />
                      {translate("best_practices","back_to_overview")}
                    </Link>
                  }
                  </div>
                    {this.renderModules(data.data.filter(obj => {
                      return obj["type"] !== 'hero';
                    }))}
                  </section>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Page)