import React from 'react'
import { withRouter } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha"

//import axios from 'axios'
// import * as actionCreators from '../../../Data/actions/actions'
// import { store } from '../../../Data/Store'

import { getRegisterUrl, getDepartmentsUrl } from '../../../config'

import { translate } from '../../../helpers'
import { getLanguage } from '../../../config'

import Alert from '../../../Components/Modules/Alert'

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios");


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstname: '',
        lastname: '',
        mail: '',
        password: '',
        password_2: '',
        company: '',
        department: '',
        policy: '',
      },
      formErrors: { firstname: '', lastname: '', mail: '', company: '', password_2: '', password: '', policy: '', department: '' },
      formValid: false,
      submitButtonDisabled: true,
      captcha: '',
      hasError: false,
      hasAlert: false,
      showForm: true,
      alertHeading: '',
      alertMessage: '',
      departments: [],
      alertType: 'danger'
    }
  }

  handleHide = () => this.setState({ hasError: false })

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const user = { ...this.state.user };
    user[name] = value;
    this.setState({ user: user }, () => { this.validateField(name, value) });
  }

  handleSelectChange = (event) => {
    const name = event.target.id
    const value = this.refs.department.value
    const user = { ...this.state.user };
    user[name] = value
    this.setState({ user }, () => { this.validateField(name, value) });
    return
  }

  onChange = (captcha) => {
    this.setState({ captcha }, this.validateForm )
  }

  validateField = (fieldName, value) => {
    const formErrors = this.state.formErrors;
    const notEmpty = ['lastname', 'firstname', 'mail', 'company', 'password_2', 'password', 'policy', 'department', ]
    const notIdentical = ['password_2', 'password' ]

    if (notEmpty.includes(fieldName)) {
      formErrors[fieldName] = value.length !== 0 ? '' : translate("auth","fill_this_field");
    }

    if (notIdentical.includes(fieldName)) {
      formErrors['password'] = formErrors['password_2'] = this.state.user.password === this.state.user.password_2 ? '' : translate("auth","password_not_identical");
    }

    this.setState({
      formErrors: formErrors,
    }, this.validateForm);
  }

  validateForm = () => {
    let valide = Object.values(this.state.formErrors).filter((v) => (v !== "")).length === 0
    valide = Object.values(this.state.user).filter((v) => (v === "")).length === 0
    valide = (this.state.captcha !== '') ? valide : false
    this.setState( { formValid: valide })
  }

  fieldIsValide = (key) => {
    return (this.state.formErrors[key] === '')
  }

  renderError = (key) => {
    if (this.state.formErrors[key] !== '') {
      return (<div className="invalid-feedback">
        {this.state.formErrors[key]}
              </div>)
    }
    return ''
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData();
    data.append("mail", this.state.user.mail)
    data.append("password", this.state.user.password)
    data.append("password_2", this.state.user.password_2)
    data.append("firstname", this.state.user.firstname)
    data.append("lastname", this.state.user.lastname)
    data.append("policy", this.state.user.policy)
    data.append("department", this.state.user.department)
    data.append("position", this.state.user.position)
    data.append("company", this.state.user.company)

    axios.post(getRegisterUrl(), data)
    .then(response => {
      // store.dispatch(actionCreators.goToLogin())
      this.setState({ hasAlert: true, showForm: false, alertHeading: '', alertMessage: response.data, alertType: 'success'})
    })
    .catch(err => {
      console.log(err.response)
      const alertHeading = translate("auth", "error")
      this.setState({ hasAlert: true, hasError: true, alertHeading, alertMessage: err.response.data })
    })
  }

  loadData = async () => {
    axios.get(getDepartmentsUrl())
    .then(response => {
      const departments = response.data
      this.setState({ departments })
    })
    .catch(err => console.log(err))
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    const lang = getLanguage()
    return (
      <React.Fragment>
        <section className="content-section" id="login-page">
          {
            this.state.hasAlert &&
            <div className="container mb-6 px-0">
              <Alert show={this.state.hasAlert} type={this.state.alertType} heading={this.state.alertHeading} text={this.state.alertMessage} handleHide={this.handleHide}/>
            </div>
          }
          {
            this.state.showForm &&
            <form onSubmit={(e) => this.handleSubmit(e)}>
            <div className="dd-auth-form">
              <div className="container bg-primary text-white">
                <div className="row py-5">
                  <div className="col-lg-10 offset-lg-1">

                    <h3 className="display-3">{translate("register","registration")}</h3>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <input type="firstname" className={this.fieldIsValide("firstname") ? "form-control" : "form-control is-invalid"} name="firstname" id="firstname" placeholder={translate("register","pre_name")} required onChange={this.handleChange}/>
                          {this.renderError("firstname")}
                        </div>

                        <div className="form-group col-md-6">
                          <input type="lastname" className={this.fieldIsValide("lastname") ? "form-control" : "form-control is-invalid"} name="lastname" id="lastname" placeholder={translate("register","name")} required onChange={this.handleChange}/>
                          {this.renderError("lastname")}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <input type="email" className={this.fieldIsValide("mail") ? "form-control" : "form-control is-invalid"} name="mail" aria-describedby="emailHelpId" placeholder={translate("auth","e-mail_required")} required onChange={this.handleChange}/>
                          {this.renderError("mail")}
                        </div>

                        <div className="form-group col-md-6">
                          <input type="Unternehmen" className={this.fieldIsValide("company") ? "form-control" : "form-control is-invalid"} name="company" id="company" placeholder={translate("register","company")} required onChange={this.handleChange}/>
                          {this.renderError("company")}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <input type="password" className={this.fieldIsValide("password") ? "form-control" : "form-control is-invalid"} name="password" id="password" placeholder={translate("auth","password_required")} required onChange={this.handleChange}/>
                          {this.renderError("password")}
                        </div>
                        <div className="form-group col-md-6">
                          <input type="password" className={this.fieldIsValide("password_2") ? "form-control" : "form-control is-invalid"} name="password_2" id="password_2" placeholder={translate("auth","password_required")} required onChange={this.handleChange}/>
                          {this.renderError("password_2")}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <input type="Position" className="form-control" name="position" id="position" placeholder={translate("register","position")} onChange={this.handleChange} />
                        </div>

                        <div className="form-group col-md-6">
                          <select ref="department" type="Unternehmensbereich" className="form-control" id="department" value={this.state.department} onChange={this.handleSelectChange}>
                            <option key="null" value="">{translate("auth","please_select")}</option>
                            {
                              this.state.departments.map((child, index) => {
                                return <option key={index} value={child}>{child}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>

                      <div className="form-check form-check-inline mb-4">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="policy" id="policy" value="1" onChange={this.handleChange} />
                          {translate("register","i_recognize_the")} <a href="/#fixme">{translate("register","termes_of_use")}</a> {translate("register","and_the")} <a href="/#fixme">{translate("register","data_protection")}</a>  {translate("register","of_the_roechling_group")}
                            </label>
                      </div>

                      <ReCAPTCHA
                        sitekey="6LdtPEsUAAAAACD8UD0FEtpuSd6qSeQpBhMX3MF6"
                        onChange={this.onChange}
                      />

                      <p />
                      <div className="container mb-5">
                        <div className="form-group row">
                          <button type="submit" className="btn btn-secondary" disabled={!this.state.formValid}>{translate("register","registrate_now")}</button>
                        </div>
                      </div>

                      <p> <span className="dd-form-asterisk">*</span> {translate("auth","required")}</p>
                      <a href={`/${lang}/auth/login`}>{translate("auth","back_to_login")}</a>
                  </div>
                </div>
              </div>
            </div>
            </form>
          }
        </section>
      </React.Fragment>
    )
  }
}
export default withRouter(Register)


