import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { getLanguage } from '../../../config';

const styles = {
  colorBlue: {
    color: '#0064b2',
  },
  colorBlueBright: {
    color: '#04bbee',
  },
  spacing: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
  headline: {
    color: '#0064b2',
    paddingBottom: '30px',
  },
}


class SearchFeatureFullText extends Component {

  render() {
    const lang = getLanguage()
    return (
      <React.Fragment>
        <h3 style={styles.colorBlue}>Volltextsuche</h3><p />
        <p><strong style={styles.colorBlueBright}>{this.props.search}</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore...&nbsp;&nbsp;></p>
        <p><strong style={styles.colorBlueBright}>{this.props.search}</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore...&nbsp;&nbsp;></p>
        <Link to={`/${lang}/search`} className="btn btn-outline-secondary">
          alle Suchergebnisse
        </Link>
      </React.Fragment>
    )
  }
}

export default SearchFeatureFullText