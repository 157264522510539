import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import SidebarNavigation from '../../../Layout/SidebarNavigation'

import { getUserProfileUrl} from '../../../config'

import { translate } from '../../../helpers'
import { getLanguage } from '../../../config'


import Alert from '../../../Components/Modules/Alert'


var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios");


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstname: '',
        lastname: '',
        mail: '',
        password: '',
        password_2: '',
        company: '',
        department: '',
        position: ''
      },
      formErrors: { firstname: '', lastname: '', mail: '', company: '', position: '', password: '', password_2: ''},
      formValid: true,
      submitButtonDisabled: true,
      hasError: false,
      errorMessage: '',
      errorType: 'danger',
      errorHeading: '',
      showModal: false,
    }
  }

  handleHide = () => this.setState({ hasError: false })

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const user = { ...this.state.user };
    user[name] = value;
    this.setState({ user: user }, () => { this.validateField(name, value) });
  }

  handlePasswordChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const user = { ...this.state.user };
    user[name] = value;
    this.setState({ user: user }, () => { this.validatePasswordField(name, value) });
  }


  onChange = (captcha) => {
    this.setState({ captcha }, this.validateForm )
  }

  validateField = (fieldName, value) => {
    const formErrors = this.state.formErrors;
    const notEmpty = ['lastname', 'firstname', 'mail', 'company', 'position', ]

    if (notEmpty.includes(fieldName)) {
      formErrors[fieldName] = value.length !== 0 ? '' : translate("auth","fill_this_field");
    }

    this.setState({
      formErrors: formErrors,
    }, this.validateForm);
  }

  validatePasswordField = () => {
    const formErrors = this.state.formErrors;

    if (this.state.user.password.length !== 0 && this.state.user.password_2.length === 0) {
      formErrors['password_2'] = translate("auth", "fill_this_field");
    }

    if (this.state.user.password.length === 0 && this.state.user.password_2.length !== 0) {
      formErrors['password'] = translate("auth", "fill_this_field");
    }

    if ((this.state.user.password.length !== 0 && this.state.user.password_2.length !== 0)) {
      if (this.state.user.password !== this.state.user.password_2) {
        formErrors['password'] = formErrors['password_2'] = translate("auth", "password_not_identical");
      } else {
        formErrors['password'] = formErrors['password_2'] = ''
      }
    }

    if (this.state.user.password.length === 0 && this.state.user.password_2.length === 0) {
      formErrors['password'] = formErrors['password_2'] = ''
    }

    this.setState({
      formErrors: formErrors,
    }, this.validateForm);
  }

  validateForm = () => {
    let valide = Object.values(this.state.formErrors).filter((v) => (v !== "")).length === 0
    this.setState( { formValid: valide })
  }

  fieldIsValide = (key) => {
    return (this.state.formErrors[key] === '')
  }

  renderError = (key) => {
    if (this.state.formErrors[key] !== '') {
      return (<div className="invalid-feedback">
        {this.state.formErrors[key]}
              </div>)
    }
    return ''
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData();
    data.append("mail", this.state.user.mail)
    data.append("password", this.state.user.password)
    data.append("password_2", this.state.user.password_2)
    data.append("firstname", this.state.user.firstname)
    data.append("lastname", this.state.user.lastname)
    data.append("department", this.state.user.department)
    data.append("position", this.state.user.position)
    data.append("company", this.state.user.company)

    axios.post(getUserProfileUrl(), data, { withCredentials: true })
    .then(response => {
      this.setState({ hasError: true, errorMessage: "", errorType: "success", errorHeading: translate("auth", "success") })
    })
    .catch(err => {
      console.log(err.response)
      this.setState({ hasError: true, errorMessage: err.response.data, errorHeading: translate("auth", "error") })
    })
  }

  onDelete = () => {
    const lang = getLanguage()
    const HOST = process.env.REACT_APP_HOST
    const logout = `${HOST}/${lang}/user/delete`
    window.location.href = logout

    this.setState({ showModal: false })
  }

  loadData = async () => {
    try {
      const userResponse = await axios(`${getUserProfileUrl()}`, { withCredentials: true })
      const userData = await userResponse.data
      const user = { ...this.state.user, ...userData }
      this.setState({ user: user})
    }
    catch (error) {
      if (error.response.status === 403) {
        this.props.goToLogin()
      }
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {

    const modalClose = () => this.setState({ showModal: false });

    return (
      <React.Fragment>
        <div className="dd-page-container" id="dd-page-container">

          { this.props.menu &&
            <SidebarNavigation menu={this.props.menu} />
          }

      <div className="container">

        <section className="content-section" id="login-page">
          {
            this.state.hasError &&
            <div className="container mb-6 px-0">
              <Alert
                show={this.state.hasError}
                text={this.state.errorMessage}
                type={this.state.errorType}
                heading={this.state.errorHeading}
                handleHide={this.handleHide}
                />
            </div>
          }
          <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="dd-auth-form">
            <div className="container bg-primary text-white">
              <div className="row py-5">
                <div className="col-lg-10 offset-lg-1">

                  <h3 className="display-3">{translate("register","registration")}</h3>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                      <div>{translate("profile", "firstname")}:</div>
                        <input
                          type="firstname"
                          className={this.fieldIsValide("firstname") ? "form-control" : "form-control is-invalid"}
                          name="firstname"
                          value={this.state.user.firstname}
                          placeholder={translate("register","pre_name")}
                          required
                          onChange={this.handleChange}/>
                        {this.renderError("firstname")}
                      </div>

                      <div className="form-group col-md-6">
                        <div>{translate("profile", "lastname")}:</div>
                        <input
                          type="lastname"
                          className={this.fieldIsValide("lastname") ? "form-control" : "form-control is-invalid"}
                          name="lastname"
                          value={this.state.user.lastname}
                          placeholder={translate("register","name")}
                          required
                          onChange={this.handleChange}/>
                        {this.renderError("lastname")}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <div>{translate("profile", "email")}:</div>
                        <input
                          type="email"
                          className={this.fieldIsValide("mail") ? "form-control" : "form-control is-invalid"}
                          name="mail"
                          value={this.state.user.mail}
                          placeholder={translate("auth","e-mail_required")}
                          required
                          onChange={this.handleChange}/>
                        {this.renderError("mail")}
                      </div>

                      <div className="form-group col-md-6">
                        <div>{translate("profile", "company")}:</div>
                        <input
                          type="text"
                          className={this.fieldIsValide("company") ? "form-control" : "form-control is-invalid"}
                          name="company"
                          value={this.state.user.company}
                          placeholder={translate("register","company")}
                          required
                          onChange={this.handleChange}/>
                        {this.renderError("company")}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <div>{translate("profile", "change_password")}:</div>
                        <input type="password" className={this.fieldIsValide("password") ? "form-control" : "form-control is-invalid"} name="password" placeholder={translate("profile", "new_password")} onChange={this.handlePasswordChange}/>
                        {this.renderError("password")}
                      </div>
                      <div className="form-group col-md-6">
                        <div>&nbsp;</div>
                        <input type="password" className={this.fieldIsValide("password_2") ? "form-control" : "form-control is-invalid"} name="password_2" placeholder={translate("profile", "new_password")} onChange={this.handlePasswordChange}/>
                        {this.renderError("password_2")}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <div>{translate("profile", "position")}:</div>
                        <input
                          type="text"
                          className="form-control"
                          name="position"
                          value={this.state.user.position}
                          placeholder={translate("register","position")}
                          onChange={this.handleChange} />
                      </div>
                    </div>

                    <p />
                    <div className="container mb-5">
                      <div className="form-group row">
                        <button type="submit" className="btn btn-secondary" disabled={!this.state.formValid}>{translate("global","save")}</button>
                      </div>
                    </div>
                    <p> <span className="dd-form-asterisk">*</span> {translate("auth", "required")}</p>
                    <hr/>
                        <Button variant="outline-light" onClick={() => this.setState({ showModal: true })}>{translate("auth", "account_delete")}</Button>

                        <Modal
                          size="sm"
                          aria-labelledby="example-modal-sizes-title-sm"
                          centered
                          show={this.state.showModal}
                          onHide={modalClose}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                              {translate("auth", "account_are_you_sure")}
                          </Modal.Title>
                          </Modal.Header>
                          <Modal.Footer>
                            <Button onClick={() => this.onDelete()}>{translate("global", "delete")}</Button>
                            <Button variant="link" onClick={modalClose}>{translate("global", "cancel")}</Button>
                          </Modal.Footer>
                        </Modal>
                </div>
              </div>
            </div>
          </div>
          </form>
        </section>
        </div>
        </div>

      </React.Fragment>
    )
  }
}
export default withRouter(Register)


