import React from 'react';
import { withRouter } from 'react-router-dom';

//import axios from 'axios'

import * as actionCreators from '../../../Data/actions/actions';
import { getForgotPasswordUrl } from '../../../config';
import { store } from '../../../Data/Store';

import { translate } from '../../../helpers';
import { getLanguage } from '../../../config';

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios");


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        mail: '',
      },
      formErrors: { mail: '' },
      formValid: false,
      submitButtonDisabled: true,
      captcha: '',
    }
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const user = { ...this.state.user };
    user[name] = value;
    this.setState({ user: user }, () => { this.validateField(name, value) });
  }

  onChange = (captcha) => {
    this.setState({ captcha }, this.validateForm )
  }

  validateField = (fieldName, value) => {
    const formErrors = this.state.formErrors;
    const notEmpty = ['mail']

    if (notEmpty.includes(fieldName)) {
      formErrors[fieldName] = value.length !== 0 ? '' : translate("auth","fill_this_field");
    }

    this.setState({
      formErrors: formErrors,
    }, this.validateForm);
  }

  validateForm = () => {
    let valide = Object.values(this.state.formErrors).filter((v) => (v !== "")).length === 0
    this.setState( { formValid: valide })
  }

  fieldIsValide = (key) => {
    return (this.state.formErrors[key] === '')
  }

  renderError = (key) => {
    if (this.state.formErrors[key] !== '') {
      return (<div className="invalid-feedback">
        {this.state.formErrors[key]}
              </div>)
    }
    return ''
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData();
    data.append("mail", this.state.user.mail)

    axios.post(getForgotPasswordUrl(), data)
    .then(response => {
      console.log(response)
      store.dispatch(actionCreators.goToLogin())
    })
    .catch(err => console.log(err))
  }

  render() {
    const lang = getLanguage()
    return (
      <React.Fragment>
        <section className="content-section" id="login-page">
          <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="dd-auth-form">
            <div className="container bg-primary text-white">
              <div className="row py-5">
                <div className="col-lg-10 offset-lg-1">

                  <h3 className="display-3">{translate("auth","forgot_password")}</h3>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input type="email" className={this.fieldIsValide("mail") ? "form-control" : "form-control is-invalid"} name="mail" id="email" aria-describedby="emailHelpId" placeholder={translate("auth","e-mail_required")} required onChange={this.handleChange}/>
                        {this.renderError("mail")}
                      </div>
                    </div>

                    <p />
                    <div className="container mb-5">
                      <div className="form-group row">
                        <button type="submit" className="btn btn-secondary" disabled={!this.state.formValid}>{translate("global","submit")}</button>
                      </div>
                    </div>

                    <p> <span className="dd-form-asterisk">*</span>{translate("auth","required")}</p>
                    <a href={`/${lang}/auth/login`}>{translate("auth","back_to_login")}</a>
                </div>
              </div>
            </div>
          </div>
          </form>
        </section>
      </React.Fragment>
    )
  }
}
export default withRouter(ForgotPassword)


