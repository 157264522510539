import React from 'react';

const Arrow = (props) => {

  const key = props.direction

  const directions = {
    right: 'rotate(45deg)',
    left: 'rotate(-135deg)',
    up: 'rotate(-45deg)',
    down: 'rotate(135deg)',
  }

  let float = (key === 'left') ? 'right' : 'left'
  float = (props.float) ? props.float : float

  const marginTop = (props.marginTop) ? props.marginTop : '0.2rem'
  const marginRight = (props.marginRight) ? props.marginRight : ''

  const styles = {
    btnAccordionRight: {
      content: '',
      border: '2px solid #fff',
      width: props.size,
      height: props.size,
      borderBottom: 'none',
      borderLeft: 'none',
      transform: directions[key],
      float: float,
      marginTop: marginTop,
      marginRight: marginRight,
      position: 'relative',
      top: '50%',
    },
  }

  return (
    <React.Fragment>
      <div style={styles.btnAccordionRight} />
    </React.Fragment>
  )
}
export default Arrow
