import React from 'react';

import FrontNavigation from './../Layout/FrontNavigation'
import Footer from './../Layout/Footer'
import BestPracticeButton from './../Layout/BestPracticeButton'
import BestPractices from '../Components/Pages/Overlay/BestPractices';
import GlobalSpinner from './GlobalSpinner'


class Front extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    }
  }

  render() {
    const sidenavCollapsed = true
    const urlPath = window.location.pathname.split("/")
    const wrapperClassName = (urlPath.length > 3) ? 'wrapper' : 'wrapper is-front'
    const cssToggle = sidenavCollapsed ? 'active' : '';

    return (
      <React.Fragment>
        <GlobalSpinner  {...this.props}>
          <div className={wrapperClassName}>
            <div id="content" className={cssToggle}>

              <FrontNavigation {...this.props} />

              <BestPracticeButton {...this.props}/>

                { (this.state.isVisible) ?
                    <div id="dd-subsite">
                      <BestPractices {...this.props} />
                    </div>
                  :
                    this.props.children
                }
            </div>
          </div>
          <Footer {...this.props} />
        </GlobalSpinner>
      </React.Fragment>
    )
  }
}

export default Front;