import React from 'react';
import ReactPlayer from 'react-player'

function Video (props) {

  const width = props.width
  const height = props.height

  return <ReactPlayer
          playing={props.loop}
          url={props.file}
          width={width}
          height={height}
          loop={props.loop}
          controls={props.controls}
          playsinline={true}
        />
}

export default Video