import React from 'react';
import { withRouter } from 'react-router-dom';

import { translate } from '../../helpers';

class ScrollToTopButton extends React.Component {
  // This Component solves Links-Viewport,
  // - scrolls to top on Navigation
  // - scrolls to top on Click
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return <div className="dd-footer__top" onClick={this.scrollToTop} style={{right:'30px',cursor:'pointer'}}>
      <div className="dd-footer__top-icon"></div>
        <div className="dd-footer__top-text">
          {translate("global","top")}
        </div>
      </div>
  }
}

export default withRouter(ScrollToTopButton);
