import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { translate } from '../../../helpers';

class Feature extends Component {
  render() {

    const styles = {
      feature: {
        backgroundImage: `url(${this.props.image})`,
      },
    }

    // href: "/strategies",
    // meta: ['Automotive', 'Studie'],
    // title: "Markenstrategie",
    // image: "http://roechling.local/assets/images/feature-4.jpg"


    return (
      <Link to={this.props.href}  className="dd-feature js-dd-feature">
        <div className="dd-feature__image" style={styles.feature} >
        </div>
        <div className="dd-feature__gradient"></div>
        <div className="dd-feature__content">
          <div className="dd-feature__meta">
            <ul>
              {this.props.meta && (this.props.meta.length > 0) &&
                this.props.meta.map((child, index) => {
                  return <li key={index}>{child}</li>
              })}
            </ul>
          </div>
          <h3 className="dd-feature__title">
            {this.props.title}
          </h3>
          <div className="dd-feature__link">
            <button className="btn btn-secondary">
              {translate("feature","know_more")}
            </button>
          </div>
        </div>
      </Link>
    )
  }
}

export default Feature