import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios'

import CartWidget from '../Modules/CartWidget';
import SidebarNavigation from '../../Layout/SidebarNavigation'

import { getCartDownloadUrl, getLanguage } from '../../config'
import { translate } from '../../helpers';

class Cart extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cart: [],
      downloadDisabled: true
    }
  }

  resetCart = () => {
    this.setState({ cart: [] }, () => this.props.initCart())
  }

  startDownload = async () => {

    const HOST = process.env.REACT_APP_HOST

    if (this.props.cart) {
      const ids = this.props.cart.map(item => item.cartId)
      const data = new FormData();
      data.append("items", ids)

      const getURL = getCartDownloadUrl() + '?items=' + ids.join(",")

      try {
        //const response = await axios.post(getCartDownloadUrl(), data, { withCredentials: true } )
        const response = await axios(getURL, { withCredentials: true } )
        window.open(HOST+response.data, '_blank');

        this.resetCart()

      } catch(err) {
        console.log(err)
      }
    }
  }

  enableDownload = () => {
    this.setState({ downloadDisabled: !this.state.downloadDisabled })
  }

  componentDidMount() {
    this.setState({ cart: this.props.cart, downloadDisabled: true})
  }

  render() {

    const LANG = getLanguage()
    const legalNotesPath = `/${LANG}/nutzungshinweise`

    const iconStyle = {
      height: '28px'
    }

    return (
      <div className="dd-page-container">

        { this.props.menu &&
          <SidebarNavigation menu={this.props.menu} />
        }

        <div className="dd-page-content">
          <div className="container">
            <section className="content-section">
              <h2 className="mb-6 text-primary">{translate("cart","your_cart_contains")}</h2>

              <CartWidget {...this.props} data={this.props.cart} />

              {(this.props.cart.length !==0) &&
              <React.Fragment>
                <div className="row">
                  <div className="col-7">
                    <h3 className="text-primary">{translate("cart", "download_as_zip")}</h3>

                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="defaultChecked2" style={iconStyle} onClick={this.enableDownload} />
                      <label className="custom-control-label" htmlFor="defaultChecked2">
                        <a href={legalNotesPath} target="_blank" rel="noopener noreferrer">{translate("cart", "download_disclaimer_check")}</a>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <button className="btn btn-primary" disabled={this.state.downloadDisabled} onClick={this.startDownload}>Download</button>
                  </div>
                  <div className="col-md-4 offset-md-4 mt-3">
                    <button className="btn btn-secondary" onClick={this.resetCart}>Warenkorb leeren</button>
                  </div>
                </div>
              </React.Fragment>
            }
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Cart)