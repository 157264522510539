import React, { Component } from 'react';
import CartWidget from '../CartWidget'
import { Link } from 'react-router-dom'

import { translate } from '../../../helpers';
import { getLanguage } from '../../../config';

const styles = {
  colorBlue: {
    color: '#0064b2',
  },
  spacing: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
  headline: {
    color: '#0064b2',
    paddingBottom: '30px',
  },
}


class SearchFeatureDownloads extends Component {

  render() {
    const lang = getLanguage()
    const downloads = [
      // { title: `${this.props.search} RGB` },
      // { title: `${this.props.search} CMYK` },
      // { title: `${this.props.search} Pantone` },
      // { title: `${this.props.search} weiss` },
      // { title: `${this.props.search} schwarz` },
      // { title: `Alle Dateien von ${this.props.search}` },
    ]

    return (
      <React.Fragment>
        <h3 style={styles.colorBlue}>Dateien</h3><p />
        <CartWidget data={downloads} />
        <Link to={`/${lang}/files`} className="btn btn-outline-secondary">
          {translate("search_modul","all_files")}
        </Link>
      </React.Fragment>
    )
  }
}

export default SearchFeatureDownloads