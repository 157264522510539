import React, { Component } from 'react';

import {filterHTML} from '../../../helpers'

class Intro extends Component {

  render() {
    return (
      <section className="dd-intro js-dd-intro container dd-section--padding mb-5">
        <div className="row">
          <div>
            <div className="dd-intro__content">
              <h1 className="dd-intro__headline">{this.props.headline}</h1>
              <div className="dd-intro__text lead" dangerouslySetInnerHTML={{__html: filterHTML(this.props.text)}}></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Intro