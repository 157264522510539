import React from 'react';

import {filterHTML} from '../../../helpers'

function Text (props) {
  return (
    <div className="row">
      <div className={props.className} dangerouslySetInnerHTML={{__html: filterHTML(props.text)}}></div>
    </div>
    )
}

export default Text