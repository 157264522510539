import React from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios'

import * as actionCreators from '../../../Data/actions/actions';
import { getNewPasswordUrl } from '../../../config';
import { store } from '../../../Data/Store';

import { translate } from '../../../helpers';

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        password: '',
        password_2: '',
      },
      formErrors: { password: '' },
      formValid: false,
      submitButtonDisabled: true,
      captcha: '',
    }
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const user = { ...this.state.user };
    user[name] = value;
    this.setState({ user: user }, () => { this.validateField(name, value) });
  }

  onChange = (captcha) => {
    this.setState({ captcha }, this.validateForm )
  }

  validateField = (fieldName, value) => {
    const formErrors = this.state.formErrors;
    const notEmpty = ['password_2', 'password']
    const notIdentical = ['password_2', 'password']

    if (notEmpty.includes(fieldName)) {
      formErrors[fieldName] = value.length !== 0 ? '' : translate("auth","fill_this_field");
    }

    if (notIdentical.includes(fieldName)) {
      formErrors['password'] = formErrors['password_2'] = this.state.user.password === this.state.user.password_2 ? '' : translate("auth","password_not_identical");
    }

    this.setState({
      formErrors: formErrors,
    }, this.validateForm);
  }

  validateForm = () => {
    let valide = Object.values(this.state.formErrors).filter((v) => (v !== "")).length === 0
    this.setState( { formValid: valide })
  }

  fieldIsValide = (key) => {
    return (this.state.formErrors[key] === '')
  }

  renderError = (key) => {
    if (this.state.formErrors[key] !== '') {
      return (<div className="invalid-feedback">
        {this.state.formErrors[key]}
              </div>)
    }
    return ''
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData();
    data.append("resetToken", this.props.match.params.resetToken)
    data.append("password", this.state.user.password)
    data.append("password_2", this.state.user.password_2)

    axios.post(getNewPasswordUrl(), data)
    .then(response => {
      console.log(response)
      store.dispatch(actionCreators.goToLogin())
    })
    .catch(err => console.log(err))
  }

  render() {
    return (
      <React.Fragment>
        <section className="content-section" id="login-page">
          <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="dd-auth-form">
            <div className="container bg-primary text-white">
              <div className="row py-5">
                <div className="col-lg-10 offset-lg-1">

                  <h3 className="display-3">{translate("newPassword","set_new_password")}</h3>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="password" className={this.fieldIsValide("password") ? "form-control" : "form-control is-invalid"} name="password" id="password" placeholder={translate("auth","password_required")} required onChange={this.handleChange}/>
                        {this.renderError("password")}
                      </div>
                      <div className="form-group col-md-6">
                        <input type="password" className={this.fieldIsValide("password_2") ? "form-control" : "form-control is-invalid"} name="password_2" id="password_2" placeholder={translate("auth","password_required")} required onChange={this.handleChange}/>
                        {this.renderError("password_2")}
                      </div>
                    </div>

                    <p />
                    <div className="container mb-5">
                      <div className="form-group row">
                        <button type="submit" className="btn btn-secondary" disabled={!this.state.formValid}>{translate("global","submit")}</button>
                      </div>
                    </div>

                    <p> <span className="dd-form-asterisk">*</span>{translate("auth","required")}</p>
                </div>
              </div>
            </div>
          </div>
          </form>
        </section>
      </React.Fragment>
    )
  }
}
export default withRouter(NewPassword)


