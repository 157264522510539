import translation from './translation.json';

//import axios from 'axios'
import Parser from 'html-react-parser';
import DOMPurify from 'dompurify'

import { getApiUrl, getMenuUrl, getFooterUrl, getUserProfileUrl} from './config';

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require("axios");


export function translate(scope, key, html=false){

  if (!window.currentLocalization) {
    return key;
  }

  const language = window.currentLocalization.locale;
  const translation_string = translation[language][scope][key];

  return (translation_string)
          ?(html)
            ?Parser(translation_string)
            :translation_string
          :'Translate me!'
}

export async function loadAPIData() {

  try {
    const data = {menu:[], footer:[], data:[]}
    const menuResponse = await axios(`${getMenuUrl()}`, { withCredentials: true })
    data.menu = await menuResponse.data

    const footerResponse = await axios(`${getFooterUrl()}`, { withCredentials: true })
    data.footer = await footerResponse.data

    const response = await axios(`${getApiUrl()}`, { withCredentials: true })
    data.data = await response.data

    return data
  }
  catch (err) {
    console.log(err)
  }
}


export async function checkLoginState() {
  try {
    await axios(`${getUserProfileUrl()}`, { withCredentials: true })
    return true
  }
  catch (error) {
    if (error.response.status !== 200) {
      return false
    }
  }
}



export function filterHTML(html) {
  // sanitize
  let clean = DOMPurify.sanitize(html, {ALLOWED_TAGS: ['a', 'br', 'p', 'strong', 'i', 'h1', 'h2', 'h3', 'h4', 'ul', 'li', 'ol'], ALLOWED_ATTR: ['rel', 'href', 'target']});
  return clean
}

export function sanitizeHTML(html) {
  let clean = DOMPurify.sanitize(html);
  return clean
}
