import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { translate } from '../../../helpers';
import { getLanguage } from '../../../config';

const styles = {
  colorBlue: {
    color: '#0064b2',
  },
  spacing: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
  headline: {
    color: '#0064b2',
    paddingBottom: '30px',
  },
}


class SearchFeatureImage extends Component {

  render() {
    const lang = getLanguage()
    return (
      <React.Fragment>
        <h3 style={styles.colorBlue}>{translate("search_modul","images")}</h3><p />

        <Link to={`/${lang}/images`} className="btn btn-outline-secondary">
          {translate("search_modul","all_images")}
        </Link>
      </React.Fragment>
    )
  }
}

export default SearchFeatureImage