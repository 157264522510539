import React from 'react'
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const SimpleSlider = props => {

  return (
    <Slider {...settings} className={"dd-product-slider js-dd-product-slider"}>
      {
        props.content.map((item, index) => {
          return (
            <div
              key={index}
              className="dd-product-slide js-dd-product-slide"
            >
              <img
                className="dd-product-slide__image"
                src={item.src}
                alt="text"
              />
              <div className={"dd-product-slide"}>
                <h3 className={"dd-product-slide__meta"}>{item.meta}</h3>
                <h1 className={"dd-product-slide__headline"}>{item.headline}</h1>
                <p className={"dd-product-slide__text"}>{item.text}</p>
                {item.button &&
                  <button type="button" className={"btn btn-secondary"}>{item.button}</button>
                }
              </div>
            </div>
          )
        })
      }
    </Slider>
  )
}


export default SimpleSlider