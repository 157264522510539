import React, { Component } from 'react';
import Breadcrumb from '../../Modules/Breadcrumb'

import {sanitizeHTML} from '../../../helpers'
import { getLanguage } from '../../../config'

const styles = {
  floatleft: {
    float: 'right',
    color: '#868F97',
  },
  colorBlue: {
    color: '#0064b2',
  },
  colorBlueBright: {
    color: '#04bbee',
  },
  grey: {
    color: '#868F97',
  },
  spacing: {
    paddingBottom: '80px',
  },
}


class SearchResultItem extends Component {

  boldString(str, find) {
      const re = new RegExp(find, 'gi');
      return str.replace(re, '<b style="color:#0064b2;">'+find+'</b>');
  }

  render() {
    const text = this.boldString(this.props.data.text, this.props.data.search);
    const lang = getLanguage()

    return (
      <div key={this.props.data.id} className="row mb-3 mt-0 col-lg-12">
        <div className="col-lg-12">
            { (this.props.data.search) ?
              <strong className="text-primary"><span style={styles.colorBlueBright}>{this.props.data.search}</span> &mdash; <a href={this.props.data.url}>{this.props.data.title}</a></strong>
              :
              <strong className="text-primary"><a href={`/${lang}/`}>{this.props.data.title}</a></strong>
          }
          {(this.props.data.meta && this.props.data.meta.type && this.props.data.meta.type !== 'HTML') &&
            <span style={styles.floatleft}>{this.props.data.meta.type} / {this.props.data.meta.size}</span>
          }
          {
            (text) &&
              <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} />
          }
          <Breadcrumb items={this.props.data.breadcrumbs}/>
          <hr />
        </div>
      </div>
    )
  }
}

export default SearchResultItem