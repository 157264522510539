import React, { Component } from 'react';


class FilterListItem extends Component {

  render() {

    return (
      <React.Fragment>
        <button type="button" onClick={ () => this.props.select(this.props.item, this.props.filter)} style={this.props.style} >
        { (this.props.item.counter) ?
          <strong>{this.props.item.label} ({this.props.item.counter})</strong>
          :
          <strong>{this.props.item.label}</strong>
        }
        </button>
        <br />
      </React.Fragment>
    )
  }
}

export default FilterListItem