import React from 'react';
import Col from 'react-bootstrap/Col'

// import { Link } from 'react-router-dom';

import axios from 'axios'

// import SearchWidget from '../Modules/Search/SearchWidget'

import FilterListItem from '../Modules/Filter/FilterListItem'
import Loading from '../../Layout/Loading'

import { translate } from '../../helpers';
import { history } from '../../Data/Store';
import { getMediaUrl, getLanguage } from '../../config';
import SvgIcon from './SvgIcon';

const iconStyle = {
  margin:0,
  fontSize: 25,
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  color: 'white',
}

const styles = {
  filterTerm: {
    display: 'inline',
    paddingLeft: '20px',
    color: '#868F97',
  },
  filterTermClose: {
    height: '10px',
    paddingLeft: '3px',
    position: 'relative',
    top: '-1px',
    strokeWidth: '2em',
  },
  spacing: {
    paddingBottom: '80px',
  },
  pSpacing: {
    paddingBottom: '20px',
  },
  grey: {
    color: '#868F97',
  },
  buttonColor: {
    border: 'none',
    outline: 'none',
    color: '#0064b2',
  },
  button: {
    border: 'none',
    outline: 'none',
    paddingLeft: '0',
  },
  buttonAddToCart: {
    border: 'none',
    outline: 'none',
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
  },
  filterOptionen: {
    margin: '0.7em',
    marginLeft: '0',
    display: 'inline',
  },
  btnAccordionDown: {
    content: '',
    marginTop: '0.3em',
    border: '2px solid #04bbed',
    width: '12px',
    height: '12px',
    borderBottom: 'none',
    borderLeft: 'none',
    transform: 'rotate(135deg)',
    float: 'right',
  },
  btnAccordionRight: {
    content: '',
    marginTop: '0.4em',
    border: '2px solid #04bbed',
    width: '12px',
    height: '12px',
    borderBottom: 'none',
    borderLeft: 'none',
    transform: 'rotate(45deg)',
    float: 'right',
  },
}

class MediaListView extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      openFilter: false,
      isLoading: false,
      data: [],
      page: 1,
      searchTerm: '',
      fileKind: '',
      department: '',
      categories: [],
      filterTerms: [],
      filterOptions: [],
      total: '',
    };
  }

  addToCart = (item) => {
    this.props.addToCart(item)
  }

  downloadItem = (item) => {
    console && console.log("downloadItem", item)
  }


  renderTooltip = props => (
    <div
        {...props}
        style={{
          ...props.style,
          backgroundColor: 'transparent',
          margin: '-120px',
        }}
      >
      <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          color: 'white',
          borderRadius: 3,
        }}>
        <i className="fa fa-plus-circle" style={iconStyle}/>
      </div>
      <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          color: 'white',
          borderRadius: 3,
        }}>
        <i className="fa fa-arrow-circle-down" style={iconStyle}/>
      </div>
    </div>
  )

  handleSearch = (term) => {
    this.setState({
      searchTerm: term,
    }, () => {
      this.loadData()
    });
  }

  handleSelectChange = (event, selector) => {
    let value = event.value
    if (Array.isArray(event)) {
      value = event
        .map(k => k.value)
        .join(',')
    }

    let label = event.label
    if (Array.isArray(event)) {
      label = event
        .map(k => k.label)
        .join(',')
    }

    let filterTerms = (this.state.filterTerms) ? this.state.filterTerms : []
    if (filterTerms.includes(label)) {
      filterTerms = filterTerms.filter(e => e !== label);
    }
    else {
      filterTerms.push(label)
    }

    this.setState({ filterTerms, [selector]: value }, () => {
      this.loadData()
    })
  }

  filterTermClick(item) {
    let filterTerms = (this.state.filterTerms) ? this.state.filterTerms : []

    if (filterTerms.includes(item)) {
      filterTerms = filterTerms.filter(e => e !== item);
    }
    else {
      filterTerms.push(item)
    }

    this.setState({ filterTerms }, () => {
      this.loadData()
    })
  }


  pushData = (newData) => {
    const data = this.state.data
    let page = this.state.page
    page++
    newData.map(k => data.push(k))
    this.setState({ data, page, isLoading: false })
  }

  loadData = async () => {
    this.setState({ isLoading: true });

    const params = {
      page: this.state.page,
      filterTerms: this.state.filterTerms,
    }

    const esc = encodeURIComponent;

    const query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')

    try {
      const searchResponse = await axios(`${getMediaUrl()}?${query}`,{ withCredentials: true })
      this.setState({ data: searchResponse.data.media, filterOptions: searchResponse.data.departments, total: searchResponse.data.total, isLoading: false })
    } catch (error) {
      const lang = getLanguage()
      history.push(`/${lang}`);
    }
  }

  componentDidMount() {
    this.loadData()
  }

  toggleFilter() {
    const openFilter = !this.state.openFilter
    this.setState( { openFilter } )
  }

  changePage = (page) => {
    this.setState({ page }, () => {
      this.loadData()
    });
  }

  pagination(lang, lastPage) {
    const out = []
    for (var i = 1; i < lastPage+1; i++) {
      const page = i
      out.push(<button key={i} type="button" style={styles.button} onClick={() => this.changePage(page)}><li className="dd-pagination__nr">{page}</li></button>)
    }
    return out
  }

  render() {
    const data = (this.state.data) ? this.state.data : this.props.data

    const filterOptions = this.state.filterOptions

    const btnAccordionAfter = (this.state.openFilter) ? styles.btnAccordionDown : styles.btnAccordionRight

    const closeSvg = require('../../assets/svg-icons/close.svg');

    const lang = getLanguage()

    const cartCartIds = []
    if (this.props.cart && Array.isArray(this.props.cart) && (this.props.cart.length > 0)) {
      this.props.cart.map(data => {
        cartCartIds.push(data.cartId)
        return null
      })
    }

    let showPagination = false
    if (this.state.total > 9) {
      showPagination = true
    }

    let firstEntry = ''
    let lastEntry = ''
    let lastPage = ''
    if (showPagination) {
      firstEntry = ((this.state.page-1) * 9) + 1
      lastEntry = firstEntry + (this.state.data.length - 1)
      lastPage = Math.ceil(this.state.total / 9)
    }

    return (
      <React.Fragment>
        {(this.state.total === 0) ?
          ''
          :
          <section>
            <div className="row mb-3">
            {
              <Col xs={12} md={8}>
                <button type="button" style={styles.button} onClick={() => this.toggleFilter()} >
                  <div style={styles.filterOptionen} >{translate("search","filter_options")}</div>
                  <div style={btnAccordionAfter} />
                </button>
              </Col>
            }
            {
              // <Col xs={6} md={4}>
              //   <SearchWidget onChangeCallback={this.handleSearch} />
              // </Col>
            }
            </div>
            {this.state.openFilter &&
              <React.Fragment>
              <div className="row" style={styles.spacing}> {
                filterOptions.map(item => {
                  return <Col key={item.label} >
                    <h5>{item.label}</h5>
                    <hr />
                    {
                      item.values.map((data, index) => {
                        const filterTerms = this.state.filterTerms
                        const buttonStyle = (filterTerms.includes(data.label)) ? styles.buttonColor : styles.button
                        return (
                          <FilterListItem
                            key={index}
                            style={buttonStyle}
                            select={this.handleSelectChange}
                            item={data}
                            filter={item.filterType}
                          />
                        )
                      })
                    }
                  </Col>})}
                  <Col xs={6} md={4}></Col>
              </div>
              {(this.state.filterTerms.length > 0) &&
                <div className="row" style={styles.pSpacing}>
                  <Col style={styles.grey} > Filter: {
                      this.state.filterTerms.map(item => {
                        return <button style={styles.button} onClick={() => this.filterTermClick(item)} >
                            <div style={styles.filterTerm} >{item} <img src={closeSvg} style={styles.filterTermClose} alt="delete" /></div>
                          </button>
                      })
                    }
                  </Col>
                </div>
              }
              </React.Fragment>
            }
          </section>
        }

        <div className="row text-center text-lg-left" style={styles.spacing}>
          {
            data.map(item => {
              return <div key={item.id} className="col-lg-4 col-md-4 col-6 mb-4">
                <div className="card dd-image-card">
                  <div className="dd-image-card__overlay">
                  { item.cartId && !(cartCartIds.includes(item.cartId)) &&
                    <span className="dd-image-card__actions" title={translate('cart', 'add_to_cart')}>
                      <button type="button" style={styles.buttonAddToCart} onClick={ () => this.addToCart(item)}>
                        <svg height="18" viewBox="0 0 64 56" width="18" xmlns="http://www.w3.org/2000/svg">
                          <g fillRule="evenodd" transform="translate(4)">
                            <path d="m24 0h8v56h-8z" /><path d="m24 0h8v56h-8z" transform="matrix(0 1 -1 0 56 0)" />
                          </g>
                        </svg>
                      </button>
                    </span>
                  }
                  </div>
                  <img className="dd-image-card__image img-fluid" src={item.filename} alt="" />
                </div>
              </div>
            })
          }
        </div>
        { (showPagination) ?
          <div className="text-lg-left" style={styles.spacing}>
            <div className="dd-pagination">
              <p className="dd-pagination__pages">
                {firstEntry} {translate('pagination', 'to')} {lastEntry} {translate('pagination', 'out_of')} {this.state.total} {translate('pagination', 'results')}
              </p>
              <ul className="pagination pagination-sm">
                <button key="first" type="button" style={styles.button} onClick={() => this.changePage(1)}>
                  <li className="dd-pagination__arrow-left">
                      <SvgIcon name="icon-arrow__list" />
                  </li>
                </button>
                {
                  this.pagination(lang, lastPage)
                }
                <button key="last" type="button" style={styles.button} onClick={() => this.changePage(lastPage)}>
                  <li className="dd-pagination__arrow-right">
                      <SvgIcon name="icon-arrow__list" />
                  </li>
                </button>
              </ul>
            </div>
          </div>
          :
          ''
        }
        <Loading showLoading={this.state.isLoading} />
      </React.Fragment>
    )
  }
}

export default MediaListView