import React from 'react';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import ScrollToTopButton from '../Components/Modules/ScrollToTopButton'

const styles = {
  line: {
    border: '0.5px solid #ffffff',
  },
  h3: {
    fontSize: '20px'
  },
  rowMarginTop: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

function Footer(props) {

  const footerData = (props.config.footer && props.config.footer.length !==0) ? props.config.footer : {menu: [], service:[]}

  return (
    <React.Fragment>
      <footer className="dd-footer dd-scripted-overlay-show">
        <Container>
          <Row>
            { footerData.menu.map((item, index) => {
              return <Col md={3} className="mb-4" key={index}>
                <p className="dd-footer__sitemap-category">{item.headline}</p>
                <Nav as="ul" className="dd-footer__sitemap-list">
                { item.links.map((link, index) => {
                  return <Nav.Item as="li" key={index}>
                    <Nav.Link className="p-0" eventKey="/link-0" target={link.target} href={link.path}>{link.title}</Nav.Link>
                  </Nav.Item>
                })}
                </Nav>
              </Col>

            })}
            <Col xs={{ order: 'first' }} md={{span: 3, order: 'last'}}>
              <ScrollToTopButton />
            </Col>
          </Row>
          <Row style={styles.rowMarginTop} className="dd-footer__menu">
            <Col md={4}>
              <p className="text dd-footer-copyright">{footerData.copyright}</p>
            </Col>
            <Col md={8} className="pl-0">
              <Nav as="ul" className="justify-content-end flex-md-row">
              { footerData.service.map((link, index) => {
                return <Nav.Item as="li" key={index} className="text">
                  <Nav.Link className="ml-0" href={link.path} target={link.target} >{link.title}</Nav.Link>
                </Nav.Item>
              })}
              </Nav>
            </Col>
          </Row>

        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer