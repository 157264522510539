const HOST = process.env.REACT_APP_HOST

export function getApiUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/data/content/`
}

export function getMediaUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/data/media/`
}

export function getMenuUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/data/menu/`
}

export function getSearchUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/data/search/`
}

export function getFooterUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/data/footer/`
}

export function getFileSearchUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/data/search/files/`
}

export function getLoginUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/login/`
}

export function getRegisterUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/registration/`
}

export function getContactUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/contact/`
}

export function getNewPasswordUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/newpassword/`
}

export function getDepartmentsUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/activationgroups/`
}

export function getForgotPasswordUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/forgotpassword/`
}

export function getUserProfileUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/user/edit/`
}

export function getCartDownloadUrl() {
  const LANG = getLanguage()
  return `${HOST}/${LANG}/cart/build`
}

export function setLocalizationByHost() {
  const path = window.location.pathname.split('/')

  if (path && path[1] === 'en') {
    return setLocalization('en-GB', 'en_GB', 'USD')
  }

  // default
  return setLocalization('de-DE', 'de_DE', 'EUR')
}

export function getLanguage() {
  if (!window.currentLocalization) {
    if (window.location.pathname.includes('/en')) {
      return 'en'
    }
    return 'de'
  }

  return window.currentLocalization.code.split('-')[0]
}


export function setLocalization(code, locale, currency) {
  window.currentLocalization = {
    code: code,
    locale: locale,
    currency: currency
  }
  return
}
