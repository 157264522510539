import React, { Component } from 'react';

class Table extends Component {

  render() {

    const styles = {
      "marginBottom": "unset"
    }

    let index = 0

    const hasHeader = this.props.hasHeader
    const data = this.props.data

    let bodyData = data
    let headerData = []
    if (hasHeader) {
      headerData = data.slice(0, 1)
      bodyData = data.slice(1)
    }

    let tableClasses = "table dd-table js-dd-table dd-table__separated"

    if (this.props.noOutline) {
      // tableClasses += " dd-table__no-outline "
      tableClasses += ""
    }

    if (this.props.noHighlights) {
      tableClasses += " dd-table__no-highlights"
    }

    if (this.props.isCellSeparated) {
      tableClasses += " dd-table__separated"
    }

    return (
      <div className="mb-4 table-responsive dd-block--table" style={{
        "borderTop": "3px solid #0064b3",
        "borderBottom": "3px solid #0064b3"
      }} >
        <table className={tableClasses} style={styles}>
          {hasHeader &&
            <thead>
              <tr>{
                headerData[0].map((col, colCount) => {
                  return (colCount < 1) ? <th key={colCount} scope="col">{col}</th> : <th key={colCount} scope="col">{col}</th>
                })
              }
              </tr>
            </thead>
          }
          <tbody>
            {
              bodyData.map((row) => {

                return <tr key={index++} >
                  {
                    row.map((col, colCount) => {
                      return (colCount >= 1) ? <td key={colCount}>{col}</td> : <th key={colCount} scope="row">{col}</th>
                    })}
                </tr>
              })}
          </tbody>
        </table>
      </div>
    )


  }
}

export default Table