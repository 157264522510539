import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { translate } from '../../../helpers';

class FeatureTextOnly extends Component {

  render() {

    const styles = {
      colorBlue: {
        backgroundColor: '#0064B1',
        border: 'none',
      },
      colorBright: {
        backgroundColor: '#05bbed',
      },
      feature: {
        backgroundImage: `url(${this.props.image})`,
      },
    }


    return (
      <Link to={this.props.href} className="dd-feature dd-feature--textonly js-dd-feature">
        <div className="dd-feature__content" style={styles.colorBright}>
          <div className="dd-feature__content-wrapper">
            <div className="dd-feature__meta">
              <ul>
                {this.props.meta && (this.props.meta.length > 0) &&
                 this.props.meta.map((child, index) => {
                    return <li key={index}>{child}</li>
                })}
              </ul>
            </div>
            <h3 className="dd-feature__title">
              {this.props.title}
            </h3>
            <p>
              {this.props.text}
            </p>
          </div>
          <div className="dd-feature__link">
            <button className="btn btn-secondary" style={styles.colorBlue}>
              {translate("feature","know_more")}
            </button>
          </div>
        </div>
      </Link>
    )
  }
}

export default withRouter(FeatureTextOnly)