

export const brand =  "Röchling-Gruppe"

export const media = [
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 1,
  format: 'PNG',
  filename: 'https://source.unsplash.com/pWkk7iiCoDM/400x300',
  categories: 'Logo,Advertisment',
  title: 'Logo 123',
  description: 'Icons are everywhere. But icons on the web have had their fair share of challenges.'
},
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 2,
  format: 'PNG',
  filename: 'https://source.unsplash.com/aob0ukAYfuI/400x300',
  title: 'Logo 456',
  categories: 'Logo',
  content: 'Lorem Ipsum',
  last_updated_at: '',
  upload_user: '',
  description: 'Lorem Ipsum'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 3,
  format: 'PNG',
  filename: 'https://source.unsplash.com/EUfxH-pze7s/400x300',
  title: 'Logo 789',
  categories: 'Advertisment, Typography',
  description: 'The quick brown fox'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 4,
  format: 'PNG',
  filename: 'https://source.unsplash.com/M185_qYH8vg/400x300',
  categories: 'Logo,Advertisment',
  title: 'Logo 123',
  description: 'Icons are everywhere. But icons on the web have had their fair share of challenges.'
},
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 5,
  format: 'JPG',
  filename: 'https://source.unsplash.com/sesveuG_rNo/400x300',
  title: 'Logo 456',
  categories: 'Logo',
  content: 'Lorem Ipsum',
  last_updated_at: '',
  upload_user: '',
  description: 'Lorem Ipsum'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 6,
  format: 'PNG',
  filename: 'https://source.unsplash.com/AvhMzHwiE_0/400x300',
  title: 'Lorem Logo 789',
  categories: 'Advertisment, Typography',
  description: 'The quick brown fox'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 7,
  format: 'JPG',
  filename: 'https://source.unsplash.com/2gYsZUmockw/400x300',
  categories: 'Logo,Advertisment',
  title: 'Image 7',
  description: 'Icons are everywhere. But icons on the web have had their fair share of challenges.'
},
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 8,
  format: 'PNG',
  filename: 'https://source.unsplash.com/EMSDtjVHdQ8/400x300',
  title: 'Lorem 8',
  categories: 'Logo',
  content: 'Lorem Ipsum',
  last_updated_at: '',
  upload_user: '',
  description: 'Lorem Ipsum'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 9,
  format: 'PNG',
  filename: 'https://source.unsplash.com/8mUEy0ABdNE/400x300',
  title: 'Logo 456',
  categories: 'Logo',
  content: 'Lorem Ipsum',
  last_updated_at: '',
  upload_user: '',
  description: 'Lorem Ipsum'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 10,
  format: 'JPG',
  filename: 'https://source.unsplash.com/G9Rfc1qccH4/400x300',
  title: 'Lorem Logo 789',
  categories: 'Advertisment, Typography',
  description: 'The quick brown fox'
  },
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 11,
  format: 'JPG',
  filename: 'https://source.unsplash.com/aJeH0KcFkuc/400x300',
  categories: 'Logo,Advertisment',
  title: 'Image 7',
  description: 'Icons are everywhere. But icons on the web have had their fair share of challenges.'
},
  {
  aspectRatio: '700x400',
  size: '200 KB',
  id: 12,
  format: 'PNG',
  filename: 'https://source.unsplash.com/p2TQ-3Bh3Oo/400x300',
  title: 'Lorem 8',
  categories: 'Logo',
  content: 'Lorem Ipsum',
  last_updated_at: '',
  upload_user: '',
  description: 'Lor'
  }
];


export const newsCollection = [
  {
  id: 1,
  text: 'Recent Study shows ...',
  footer: 'Last Updated at 24.01.2019',
  subheadline: 'Is a dog lazy?',
  headline: "A yellow pencil with envelopes on a clean, blue backdrop!",
  url:"/news/1",
  image:"/img/portfolio.jpg"
},
  {
  id: 2,
  text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
  footer: 'Last Updated at 24.01.2019',
  subheadline: "Carpe Diem",
  headline: "Lorem Ipsum",
  url:"/news/2",
  image:"/img/portfolio.jpg"
},
  {
  id: 3,
  subheadline: "demistify foo bar",
  headline: "no magic, just rules",
  text: ' ...',
  footer: 'Last Updated at 24.01.2019',
  url:"/news/3",
  image:"/img/portfolio.jpg"
},
  {
  id: 4,
  subheadline: "Clickable",
  headline: "the quick brown fox jumps over the lazy dog!",
  text: ' ...',
  footer: 'Last Updated at 24.01.2019',
  url:"/news/4",
  image:"/img/portfolio.jpg"
},
  {
  id: 5,
  subheadline: "Clickable",
  headline: "the quick brown fox jumps over the lazy dog!",
  text: ' ...',
  footer: 'Last Updated at 24.01.2019',
  url: "/news/4",
  image: "/img/portfolio.jpg"
},
  {
  id: 6,
  subheadline: "Clickable",
  headline: "the quick brown fox jumps over the lazy dog!",
  text: ' ...',
  footer: 'Last Updated at 24.01.2019',
  url: "/news/4",
  image: "/img/portfolio.jpg"
}
]

export const imageDetail = {
  id: 1,
  title: 'Logo 123',
  src: 'https://source.unsplash.com/pWkk7iiCoDM/400x300',
  description: 'Icons are everywhere. But icons on the web have had their fair share of challenges.',
  downloads: [
    {
      title: 'small',
      fileSize: '(100x100)',
      file: 'https://source.unsplash.com/pWkk7iiCoDM/100x100'
},
    {
      title: 'middle',
      fileSize: '(500x500)',
      file: 'https://source.unsplash.com/pWkk7iiCoDM/500x500'
},
    {
      title: 'large',
      fileSize: '(1000x1000)',
      file: 'https://source.unsplash.com/pWkk7iiCoDM/1000x1000'
},
    {
      title: 'original',
      fileSize: '(10000x10000)',
      file: 'https://source.unsplash.com/pWkk7iiCoDM/10000x10000'
}
],
  keywords: [
    'Lorem ipsum',
    'dolor sit amet',
    'consetetur sadipscing',
    'elitr sed diam',
    'nonumy eirmod tempor'
],
  categories: [
    'Lorem',
    'Ipsum',
    'Dolor',
    'Lorem',
    'Ipsum',
    'Dolor'
],
  publication: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
  metadata: {
    size: '3200x2400',
    format: 'PNG',
    filesize: '1MB',
    uploadUser: 'Alex',
    modified: '02.02.2019 15:01'
}
}

export default media

